<nav class="navbar navbar-expand-md navbar-light navbar-area">
    <div class="container logo_img">
        <a class="navbar-brand" href="#" routerLink="/app-landing">
            <img src="assets/img/integratedsollogo.png" alt="logo" style="width:250px;">
        </a>



        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>


        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">

                <li class="nav-item"><a class="nav-link " routerLink="/app-landing">{{ 'demo.appprocessing1' | translate }}</a></li>

                <li class="nav-item"><a class="nav-link " routerLink="/app-merchant">{{ 'demo.appprocessing2' | translate }}</a></li>


                <li class="nav-item"><a class="nav-link" routerLink="/gateway-services">{{ 'demo.appprocessing3' | translate }}</a></li>


                <li class="nav-item">

                    <div class="dropdown">
                        <div class="nav-link" >{{ 'demo.appprocessing4' | translate }}
                            <i class="fa fa-caret-down"></i>
                        </div>
                        <div class="dropdown-content">

           <li> <a [routerLink]="" (click)="useLanguage('en')" >English</a></li>
                <li> <a [routerLink]="" (click)="useLanguage('de')">Spanish</a></li>
                <li><a routerLink="/app-landing">Chinese</a> </li>


                </div>
                </div>

                </li>
            </ul>

            <div class="navbar-btn">
                <a href="https://appopay.com/merchantadmin/#/system/login" target="_blank" style="  color: #fff !important;">{{ 'demo.appprocessing5' | translate }}</a>
            </div>
            <div class="navbar-btn">
                <a  href="#"routerLink="/contact-us" style="  color: #fff !important;">{{ 'demo.appprocessing6' | translate }}</a>
            </div>
        </div>
    </div>
</nav>

<style>
    .dropdown {
        float: left;
        overflow: hidden;
    }

    .dropdown {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding: 1px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
    }




    .dropdown-content {
        display: none;
        position: fixed;

        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        float: none;
        color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        background-color: #0899e7;
    }

    .dropdown-content a:hover {
        background-color: #f2f2f22e!important;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
</style>
<div id="home" class="main-banner banner-bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <div style="margin-top: 12.5rem !important;">
                           <!-- <img _ngcontent-iof-c31="" src="assets/img/app-landing/processing.jpg" alt="iphone">-->
                          <!--
                             <h1>Let us help you grow </h1>
                            <div class="banner-btn">
                                <a routerLink="/app-landing">Download App</a>
                                <a href="https://vimeo.com/58363288" class="banner-video popup-vimeo"><i class="flaticon-play-button"></i> Watch Video</a>
                            </div>-->
                        </div>
                    </div>

                   <!-- <div class="col-lg-5">
                        <div class="banner-img text-right">
                            <img src="assets/img/bulb.png" alt="iphone">
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>

</div>




<section class="how-use pt-100">
    <div class="container">


        <div class="row align-items-center">
            <div class="col-lg-6 ap_sec">
                <div class="row align-items-center">
                    <div class="testimonial-img">
                        <img src="assets/img/app-landing/banner06122.jpg" alt="map image">


                    </div>

                </div>

            </div>

            <div class="col-lg-6 ap_box">

                <div class="media">

                    <div class="media-body ml-4">
                        <h3>{{ 'demo.appprocessing7' | translate }} </h3>
                {{ 'demo.appprocessing8' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="how-use pt-100">
    <div class="container">


        <div class="row align-items-center">
            <div class="col-lg-6 ap_sec sec2_img">

                <div class="row align-items-center">
                    <div class="testimonial-img">
                        <img src="assets/img/app-landing/banner0612.jpg" alt="map image">


                    </div>

                </div>
            </div>
            <div class="col-lg-6 ap_box">


                <div class="media">

                    <div class="media-body">
                        <h3>{{ 'demo.appprocessing9' | translate }}</h3>
                    {{ 'demo.appprocessing10' | translate }}
                    </div>
                </div>
            </div>

            <div class="col-lg-6 ap_sec sec2_desktop">

                <div class="row align-items-center">
                    <div class="testimonial-img">
                        <img src="assets/img/app-landing/banner0612.jpg" alt="map image">


                    </div>

                </div>
            </div>
        </div>
    </div>
</section>





<section class="how-use pt-100">
    <div class="container">


        <div class="row align-items-center">
            <div class="col-lg-6 ap_sec">
                <div class="row align-items-center">
                    <div class="testimonial-img">
                        <img src="assets/img/app-landing/banner30612.jpg" alt="map image">


                    </div>

                </div>
            </div>

            <div class="col-lg-6 ap_box">

                <div class="media">

                    <div class="media-body ml-4">
                        <h3>{{ 'demo.appprocessing11' | translate }} </h3>
                    {{ 'demo.appprocessing12' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="how-use pt-100">
    <div class="container">


        <div class="row align-items-center">
            <div class="col-lg-6 ap_sec sec2_img">
                <div class="row align-items-center">
                    <div class="testimonial-img">
                        <img src="assets/img/app-landing/banner40612.jpg" alt="map image">


                    </div>

                </div>

            </div>
            <div class="col-lg-6 ap_box">
                <div class="media">

                    <div class="media-body">
                        <h3>{{ 'demo.appprocessing13' | translate }} </h3>
					{{ 'demo.appprocessing14' | translate }}
                    </div>
                </div>
            </div>

            <div class="col-lg-6 ap_sec sec2_desktop">
                <div class="row align-items-center">
                    <div class="testimonial-img">
                        <img src="assets/img/app-landing/banner40612.jpg" alt="map image">


                    </div>

                </div>

            </div>
        </div>
    </div>
</section>

<section class="how-use pt-100">
    <div class="container">


        <div class="row align-items-center">
            <div class="col-lg-6 ap_sec">
                <div class="row align-items-center">
                    <div class="testimonial-img">
                        <img src="assets/img/app-landing/banner50612.jpg" alt="map image">


                    </div>

                </div>
            </div>

            <div class="col-lg-6 ap_box">

                <div class="media">

                    <div class="media-body ml-4">
                        <h3>{{ 'demo.appprocessing15' | translate }} </h3>
                   {{ 'demo.appprocessing16' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section id="apps" class="our-apps " style="padding-bottom: 220px;">



    <div id="contact" class="contact-section ">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 ">
                    <div class="contact-form">
                        <h2>{{ 'demo.appprocessing17' | translate }}</h2>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" placeholder="{{ 'demo.appprocessing18' | translate }}">
                                    </div>
                                </div>

                                <div class="col-md-12 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" placeholder="{{ 'demo.appprocessing19' | translate }}">
                                    </div>
                                </div>

                                <div class="col-md-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="{{ 'demo.appprocessing20' | translate }}">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="{{ 'demo.appprocessing21' | translate }}"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn contact-btn">{{ 'demo.appprocessing22' | translate }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
<app-footer></app-footer>

