<!-- <app-header></app-header>
<section class=" pt-100" style="margin-top:100px;">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-12 ">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">
                    <div class="section-title">

                        <h2>PRIVACY POLICY</h2>
                        <p>Policy Effective as of October 1, 2019.</p>
                    </div>

                    <div class="media">

                        <div class="media-body">
                            <h3 class="mt-0"></h3>
                            This Privacy Policy applies to STUFFRS  S.A DBA   (“APPOPAY”)the Site, www.appopay.com. (“APPOPAY”) ‘Site’,
                            by definition for this agreement includes our Service application(s) available via internet/web access,
                            mobile and smart phones, tablets, or any other technology that allows you to access the Service,
                            which exists now or in the future. Please read this carefully as this policy is legally binding when you use our Services.
                            Check the Customer Agreement for the meaning of defined words.
                            This privacy policy describes how collects and uses the personal information you provide on our web site: www.appopay.com.
                            It describes the choices available to you, regarding our use of your personal information, how it can be accessed, reviewed and updated.
                        </div>
                    </div>


                    <div class="media">

                        <div class="media-body">
                            <h3 class="mt-0"></h3>
                            We are serious and committed to protecting our customers' personal
                            information, privacy and
                            security. We use a secure server to store your personal information. All
                            information you
                            provide to us is stored on our secure servers. All payment transactions
                            will be encrypted
                            using Transport Layer Security technology.

                            As you are aware, the transmission of information via the internet is
                            not completely secure.
                            Although we will do our utmost to protect your personal data, we cannot
                            guarantee the
                            security of your data during transmission, and any transmission is at
                            your own risk. Once we
                            have received your information, we will use strict procedures and
                            security features to try
                            to prevent unauthorized access.<br><br>

                            We restrict access of your personal information to those employees of
                            Appopay who have a
                            business reason for knowing such information. We continuously educate
                            and train our
                            employees about the importance of confidentiality and privacy of
                            customer information. We
                            maintain physical, electronic and procedural safeguards that comply with
                            relevant laws and
                            regulations to protect your personal information from unauthorized
                            access.<br><br>

                            This Privacy Statement explains what personal information we collect
                            about you, with whom we
                            share it, and how you [The user of the Service] can direct us not to
                            share certain
                            information with certain parties. "Personal Information" refers to
                            information such as name
                            and email that can be used to identify an individual. You should read
                            this Privacy Policy in
                            its entirety. Certain sections may apply differently to residents of
                            different
                            jurisdictions.
                        </div>
                    </div>


                    <div class="media">

                        <div class="media-body">


                            <h3 class="mt-2">By using our services, you agree you are accepting the
                                terms of this
                                Privacy Notice.</h3>
                            We may update this privacy statement, from time to time, to reflect
                            changes to our
                            information practices. If we make any material changes we will notify
                            you by email (sent to
                            the e-mail address specified in your account) or by means of a notice on
                            this Site prior to
                            the change becoming effective. We encourage you to, periodically; review
                            this page for the
                            latest information on our privacy practices.
                        </div>
                    </div>
                    <div class="media">

                        <div class="media-body">


                            <h3 class="mt-2">WHY DO WE COLLECT INFORMATION AND DATA?</h3>
                            We collect; process and retain personal information from you and any
                            devices you may use
                            when you use our Services, or you provide it to us on a web form, when
                            you update your
                            information or otherwise correspond with us regarding our Services.
                            Additionally, we may
                            collect personal information from third parties. We collect information
                            for the following
                            purposes:

                        </div>
                    </div>


                    <div class="feature-text">

                        <div class="media-body">
                            <h3 class="mt-0"></h3>

                            <ul class="aboutli">
                                <li><i class="flaticon-checked mr-2"></i> To process your
                                    transactions and contact you,
                                    as necessary
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> To verify your identity
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> To collect payment for
                                    your use of the Service
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> To troubleshoot problems
                                    with the Service
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> To detect and prevent
                                    fraud and other illegal
                                    uses of the Service
                                </li>
                                <li><i class="flaticon-checked mr-2"></i>To enhance and simplify the
                                    user experience
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> To send you marketing
                                    notices, service
                                    updates, and promotional offers
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> To send transaction alerts
                                    to you and/or the
                                    recipient of your remittance
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div class="media">

                        <div class="media-body">


                            <h3 class="mt-2">HOW DO WE COLLECT INFORMATION?</h3>
                            We collect personal information:
                        </div>
                    </div>


                    <div class="feature-text">

                        <div class="media-body">
                            <h3 class="mt-0"></h3>

                            <ul class="aboutli">
                                <li><i class="flaticon-checked mr-2"></i> Directly provided by you
                                    when you use our
                                    Services;
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> Automatically through your
                                    use of our
                                    Services;
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> From using cookies, web
                                    beacons, and similar
                                    technologies; and
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> From other third party
                                    sources.
                                </li>

                            </ul>
                        </div>
                    </div>


                    <div class="media">

                        <div class="media-body">


                            <h3 class="mt-2">PERSONAL INFORMATION WE MAY COLLECT?</h3>
                            <h4 class="mt-2">Information You Give Us</h4>
                            The information that we collect directly from you includes:
                        </div>
                    </div>


                    <div class="feature-text">

                        <div class="media-body">
                            <h3 class="mt-0"></h3>

                            <ul class="aboutli">
                                <li>Information that you provide us when you sign up to use our
                                    Service, such as your
                                    name, address, e-mail address, and telephone number. This also
                                    includes information
                                    you provide through your continued use of our Services,
                                    participate in discussion
                                    boards or other social media functions on our Website or App,
                                    enter a competition,
                                    promotion or survey, and when you report a problem with our
                                    Services. The
                                    information you give us may include certain sensitive
                                    information including
                                    financial information (including credit card, debit card, or
                                    bank account
                                    information), date of birth and social security number, and
                                    other identification
                                    documents. We may request to validate your identity through
                                    personal description and
                                    photographs for the safety and security of establishing your
                                    Account with the
                                    Service.
                                </li>
                                <li> We may also need additional commercial and/or identification
                                    information from your
                                    e.g. if you send or receive certain high-value or high volume
                                    transactions or as
                                    needed to comply with our anti-money laundering obligations
                                    under applicable law.
                                </li>
                                <li> To facilitate our Service, we request certain third party
                                    personal information from
                                    you such as your recipient's full name, physical address, email
                                    address, and phone
                                    number. We may also collect from you the recipient's sensitive
                                    financial information
                                    including their name, bank account and routing number. If you
                                    give us personal
                                    information about someone else, you must do so only on their
                                    explicit and prior
                                    consent. You have to inform them how we collect, use, disclose
                                    and retain their
                                    personal information according to our Privacy Notice.
                                </li>
                                <li> Information about your usage of the Service, including your
                                    transaction history,
                                    and how you choose to pay/fund your remittance, and to whom you
                                    use the Service to
                                    send or receive money, along with the recipient’s collection
                                    method.
                                </li>
                                <li> To detect and prevent fraud and other illegal uses of the
                                    Service
                                </li>
                                <li> To enhance and simplify the user experience</li>
                                <li>To send you marketing notices, service updates, and promotional
                                    offers
                                </li>
                                <li>To send transaction alerts to you and/or the recipient of your
                                    remittance
                                </li>
                            </ul>
                            <p>You decide how much information to share with us in most cases, but
                                not sharing required
                                information may limit your ability to engage in certain activities,
                                such as payment
                                information required to complete a transaction. </p>
                        </div>
                    </div>


                    <div class="media">

                        <div class="media-body">


                            <h3 class="mt-2">Information Collected Automatically Through Your Use of
                                the Service</h3>

                            When you use or interact with our Site and Services, we receive and
                            store information
                            generated by your activity, like usage data and other information
                            automatically collected
                            from your browser or mobile device. The Information that we
                            automatically obtain from you
                            includes:
                        </div>
                    </div>


                    <div class="feature-text">

                        <div class="media-body">
                            <h3 class="mt-0"></h3>
                            <ul class="aboutli">
                                <li><i class="flaticon-checked mr-2"></i> Details of the
                                    transactions you carry out when
                                    using our Services, including geographic location from which the
                                    transaction
                                    originates;
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> Information about the
                                    hardware and software
                                    you use when accessing the Service;
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> Technical information,
                                    including the Internet
                                    protocol (IP) address used to connect your computer to the
                                    Internet, your login
                                    information, browser type and version, time zone setting,
                                    geolocation data obtained
                                    from your mobile device, browser plug-in types and versions,
                                    operating system and
                                    platform;
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> Information about your
                                    visit, including the
                                    full Uniform Resource Locators (URL) clickstream to, through and
                                    from our Website or
                                    App (including date and time); products you viewed or searched
                                    for; page response
                                    times, download errors, length of visits to certain pages, page
                                    interaction
                                    information (such as scrolling, clicks, and mouse-overs), and
                                    methods used to browse
                                    away from the page and any phone number used to call our
                                    Customer Support number.
                                </li>

                            </ul>

                        </div>
                    </div>


                    <div class="media">

                        <div class="media-body">


                            <h3 class="mt-2"> Information We Receive from Other Sources</h3>


                            We may supplement the information we collect with personal information
                            from third parties.
                            For example, we may collect and use demographic information that is
                            publicly available,
                            additional contact information, and information obtained by identity
                            verification services
                            and credit reporting agencies, as allowed by applicable national laws.
                            <p>We may also receive information about you if you use any of the other
                                websites we operate
                                or the other services we provide. We are also working closely with
                                third parties and may
                                receive information about you from them. For example:
                            </p>
                        </div>
                    </div>


                    <div class="feature-text">

                        <div class="media-body">
                            <h3 class="mt-0"></h3>
                            <ul class="aboutli">
                                <li><i class="flaticon-checked mr-2"></i> The banks you use to
                                    transfer money to us will
                                    provide us with your basic personal information, such as your
                                    name and address, as
                                    well as your financial information such as your bank account
                                    details;
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> Business partners may
                                    provide us with your
                                    name and address, as well as financial information, such as card
                                    payment
                                    information;
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> Advertising networks,
                                    analytics providers and
                                    search information providers may provide us with pseudonymised
                                    information about
                                    you, such as confirming how you found our website;
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> Credit reference agencies
                                    do not provide us
                                    with any personal information about yourself, but may be used to
                                    corroborate the
                                    information you have provided to us.
                                </li>

                            </ul>
                            <p> If you log in to our Services using your social media account (for
                                example, Facebook or Google) we will receive relevant information
                                that is necessary to enable our Services and authenticate you. The
                                social media network will provide us with access to certain
                                information that you have provided to them, including your name,
                                profile image and e-mail address. We use such information, together
                                with any other information you directly provide to us when
                                registering or using our Services, to create your account and to
                                communicate with you about the information, products and services
                                that you request from us. You may also be able to, specifically
                                request that we have access to the contacts in your social media
                                account so that you can send a referral link to your family and
                                friends. We will use, disclose and store all of this information in
                                accordance with this privacy policy.</p>

                            <p>Such social media sites may also give us automatically access to
                                certain personal information retained by them about you (e.g.
                                content viewed by you, content liked by you, or may have clicked
                                on). Your interactions with these Features are governed by the
                                privacy policy of the company providing it. </p>
                        </div>
                    </div>


                    <div class="media">

                        <div class="media-body">


                            <h3 class="mt-2">INFORMATION COLLECTED USING COOKIES, WEB BEACONS, AND
                                SIMILAR TECHNOLOGIES</h3>


                            COOKIES, beacons, tags and scripts are used by Appopay and our partners,
                            affiliates, or analytics or service providers. These technologies are
                            used:
                        </div>
                    </div>


                    <div class="feature-text">

                        <div class="media-body">
                            <h3 class="mt-0"></h3>
                            <ul class="aboutli">
                                <li><i class="flaticon-checked mr-2"></i> To enhance and simplify
                                    the user experience on our home page and throughout our site, to
                                    remember users’ settings (e.g. language preference, preferred
                                    country of remittance, and for authentication); and

                                </li>
                                <li><i class="flaticon-checked mr-2"></i> Business partners may
                                    provide us with your
                                    name and address, as well as financial information, such as card
                                    payment
                                    information;
                                </li>
                                <li><i class="flaticon-checked mr-2"></i> To analyze trends,
                                    administering the site, tracking users’ movements around the
                                    site and to gather demographic information about our user base
                                    as a whole. We may receive reports based on the use of these
                                    technologies by these companies on an individual as well as
                                    aggregated basis.

                                </li>
                                <li><i class="flaticon-checked mr-2"></i> Our Services use cookies
                                    to distinguish you from other users. This helps us to provide
                                    you with a good experience and allows us to improve our
                                    Services. For detailed information on the cookies we use and the
                                    purposes for which we use them see our Cookie Policy.


                                </li>

                            </ul>
                            <p>Users can control the use of cookies at the individual browser level.
                                If you reject cookies, you may still use our site, but your ability
                                to use some features or areas of our site may be limited. </p></div>
                            <div class="media-body">
                            <p>Stuffrs S.A. ("us", "we", or "our") operates the ChenChen mobile application (the "Service").</p>

                            <p> We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.
                                Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
                                
                            <p> Information Collection And Use
                                We collect several different types of information for various purposes to provide and improve our Service to you.
                                
                               <p>Types of Data Collected</p> 
                               <h6>Personal Data</h6>
                               <p> While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
                                
                                - Email address
                                - First name and last name
                                - Phone number
                                - Address, State, Province, ZIP/Postal code, City
                                - Cookies and Usage Data
                                - Address Book (Contacts)
                                
                                When you access the Service through a mobile device, we may collect address book information that includes your contacts.</p>
                                
                                <h6>Usage Data</h6>
                               <p>When you access the Service by or through a mobile device, we may collect certain information automatically, including,
                                 but not limited to, the type of mobile device you use, your mobile device unique ID,
                                 the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers,
                                 and other diagnostic data ("Usage Data").</p>
                                
                                <h6>Tracking & Cookies Data</h6>
                               <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                                
                               <p>Cookies are files with small amounts of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device.
                                Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                                
                               <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However,
                                 if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                                
                               <p> Examples of Cookies we use:
                                - Session Cookies. We use Session Cookies to operate our Service.
                                - Preference Cookies. We use Preference Cookies to remember your preferences and various settings.
                                - Security Cookies. We use Security Cookies for security purposes.</p>
                                
                               <h6>Use of Data</h6>
                                <p>Stuffrs S.A. uses the collected data for various purposes:
                                
                                - To provide and maintain the Service
                                - To notify you about changes to our Service
                                - To allow you to participate in interactive features of our Service when you choose to do so
                                - To provide customer care and support
                                - To provide analysis or valuable information so that we can improve the Service
                                - To monitor the usage of the Service
                                - To detect, prevent, and address technical issues
                                - To promote this app using your Address Book (Contacts)</p>
                                
                                <h6>Transfer Of Data</h6>
                                <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province,
                                     country, or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction.</p>
                                
                                <p>If you are located outside Panama and choose to provide information to us, please note that we transfer the data, including Personal Data,
                                     to Panama and process it there.</p>
                                
                                <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                                
                                <p>Stuffrs S.A. will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy
                                 and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including
                                 the security of your data and other personal information.</p>
                                
                                <h6>Disclosure Of Data</h6>
                                <h6>Requirements</h6>
                                <p>Stuffrs S.A. may disclose your Personal Data in the good faith belief that such action is necessary to:
                                
                                - To comply with a legal obligation
                                - To protect and defend the rights or property of Stuffrs S.A.
                                - To prevent or investigate possible wrongdoing in connection with the Service
                                - To protect the personal safety of users of the Service or the public
                                - To protect against legal liability</p>
                                
                               <p>Stuffrs S.A. will not disclose your Address Book (Contacts) with anyone.</p>
                                
                                <h6>Security Of Data</h6>
                                <p>The security of your data is important to us, but remember that no method of transmission over the Internet,
                                    or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data,
                                    we cannot guarantee its absolute security.</p>
                                
                                <h6>Service Providers</h6>
                                <p>We may employ third-party companies and individuals to facilitate our Service ("Service Providers"),
                                    to provide the Service on our behalf, to perform Service-related services,
                                    or to assist us in analyzing how our Service is used.</p>
                                
                                <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                                
                                <h6>Personal Data</h6><p>
                                * ID Type Either Passport or National Id(For Passport Front Photo, For Id Both Side Photo)
                                * Mobile Number
                                * First Name
                                * Middle Name
                                * Last Name
                                * Name On Card 
                                * ID Number
                                * Gender
                                * Marital Status
                                * Date Of Birth
                                * Email Id
                                * PEP Statement
                                * Address
                                * Mobile Login Pin 6 Digits
                                * Device Id for Uniquely identify the device</p>
                                <p>
                                If User Sign Up With ChenChen Card
                                   * Card Number
                                   * Expiry Date
                                   * CVV
                                
                                <h6>Links To Other Sites</h6>
                               <p>Our Service may contain links to other sites that are not operated by us. If you click on a third-party link,
                                 you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                                
                                <p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                                
                                <h6>Children's Privacy</h6>
                                <p>Our Service does not address anyone under the age of 18 ("Children").</p>
                                
                                <p>We do not knowingly collect personally identifiable information from anyone under the age of 18.
                                     If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us.
                                      If we become aware that we have collected Personal Data from children without verification of parental consent,
                                       we take steps to remove that information from our servers.</p>
                                
                               <h6>Changes To This Privacy Policy</h6>
                                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                                
                               <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
                                
                               <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                                
                                <h6>Contact Us</h6>
                                <p>If you have any questions about this Privacy Policy, please contact us:</p>
                                
                               <p>- By email: support@appopay.app</p>
                                <p>- By phone number: +507 6622 8458</p>
                        </div>
                    </div>


                </div>
            </div>


        </div>
    </div>
</section>
<app-footer></app-footer> -->

<app-header></app-header>
<section class=" pt-100" style="margin-top:100px;">
    <div class="container">
        <div class="row align-items-center">
            <head>
                <title>Privacy Policy</title>
            </head>
            <body>
            <h1>Privacy Policy</h1>
            <p>Last updated: January 18, 2025</p>
            <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
            <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
            <h2>Interpretation and Definitions</h2>
            <h3>Interpretation</h3>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            <h3>Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
                <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
                <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
                <li><strong>Application</strong> refers to CHENCHEN, the software program provided by the Company.</li>
                <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to STUFFRS S.A, Calle Punta Darién, Punta Pacifica, P.H. Torre de las Américas, Suite 2905, Panamá City, Panamá.</li>
                <li><strong>Country</strong> refers to: Panama</li>
                <li><strong>Device</strong> means any device that can access the Service such as a computer, a cell phone or a digital tablet.</li>
                <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
                <li><strong>Service</strong> refers to the Application.</li>
                <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
                <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            </ul>
            <h2>Collecting and Using Your Personal Data</h2>
            <h3>Types of Data Collected</h3>
            <h4>Personal Data</h4>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
            <ul>
                <li>ID Type Either Passport or National Id (For Passport Front Photo, For Id Both Side Photo)</li>
                <li>Mobile Number</li>
                <li>First Name</li>
                <li>Middle Name</li>
                <li>Last Name</li>
                <li>Name On Card</li>
                <li>ID Number</li>
                <li>Gender</li>
                <li>Marital Status</li>
                <li>Date Of Birth</li>
                <li>Email Id</li>
                <li>PEP Statement</li>
                <li>Address</li>
                <li>If User Sign Up With ChenChen Card
                    <ul>
                        <li>Card Number</li>
                        <li>Expiry Date</li>
                        <li>CVV</li>
                    </ul>
                </li>
                <li>Mobile Login Pin 6 Digits</li>
                <li>Device Id for Uniquely identify the device</li>
            </ul>
            <h4>Usage Data</h4>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
            <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
            <h4>Information Collected while Using the Application</h4>
            <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</p>
            <ul>
                <li>Pictures and other information from your Device's camera and photo library</li>
            </ul>
            <p>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</p>
            <p>You can enable or disable access to this information at any time, through Your Device settings.</p>
            <h3>Use of Your Personal Data</h3>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
                <li>To provide and maintain our Service, including to monitor the usage of our Service.</li>
                <li>To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                <li>For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
                <li>To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                <li>To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
                <li>To manage Your requests: To attend and manage Your requests to Us.</li>
                <li>For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>
                <li>For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</li>
            </ul>
            <p>We may share Your personal information in the following situations:</p>
            <ul>
                <li>With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
                <li>For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                <li>With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                <li>With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                <li>With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
                <li>With Your consent: We may disclose Your personal information for any other purpose with Your consent.</li>
            </ul>
            <h3>Retention of Your Personal Data</h3>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
            <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
            <h3>Transfer of Your Personal Data</h3>
            <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
            <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
            <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
            <h3>Delete Your Personal Data</h3>
            <p>You can contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
            <p><a href="https://appopay.com/#/contact-us">https://appopay.com/#/contact-us</a></p>
            <p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
            <h3>Disclosure of Your Personal Data</h3>
            <h4>Business Transactions</h4>
            <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
            <h4>Law enforcement</h4>
            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
            <h4>Other legal requirements</h4>
            <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
            <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
            </ul>
            <h3>Security of Your Personal Data</h3>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
            <h2>Children's Privacy</h2>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
            <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
            <h2>Links to Other Websites</h2>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, you will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>
            <h2>Changes to this Privacy Policy</h2>
            <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
            <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, you can contact us:</p>
            <ul>
                <li>By email: support@appopay.com</li>
                <li>By phone number: +507 6622 8458</li>
            </ul>
            </body>
        </div>
    </div>
</section>
<app-footer></app-footer>
