<nav class="navbar navbar-expand-md navbar-light navbar-area pt-100 pb-50" style="background-color: #079ee8;">
    <div class="container">
        <a class="navbar-brand" routerLink="/app-landing">
            <img src="assets/img/appopaynews.png" alt="logo" style="width:100px;">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">

                <li class="nav-item"><a class="nav-link active" routerLink="/app-merchant">Merchant</a></li>




    <li class="nav-item"><a class="nav-link" routerLink="/agent">Agent</a></li>

    <li class="nav-item"><a class="nav-link" routerLink="/qrcode">QR Code</a></li>
    <li class="nav-item">

        <div class="dropdown">
            <div class="nav-link" routerLink="/processing">Language
                <i class="fa fa-caret-down"></i>
            </div>
            <div class="dropdown-content">

    <li> <a  routerLink="/app-landing">English</a></li>
    <li> <a routerLink="/app-landing">Spanish</a></li>
    <li><a routerLink="/app-landing">Chinese</a> </li>


    </div>
    </div>

    </li>

    </ul>

    <div class="navbar-btn">
        <a href="https://appopay.com/appopayadmin/#/system/login" target="_blank"> Customer Login</a>
    </div>
    <div class="navbar-btn">
        <a href="https://appopay.com/merchantadmin/#/system/login" target="_blank">Merchant Login</a>
    </div>
    </div>
    </div>
</nav>
<style>
    .dropdown {
        float: left;
        overflow: hidden;
    }

    .dropdown {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding: 1px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
    }




    .dropdown-content {
        display: none;
        position: fixed;

        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        float: none;
        color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        background-color: #0899e7;
    }

    .dropdown-content a:hover {
        background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
</style>


<section class="why-choose pt-100 pb-50 " style="top:100px;">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-12 offset-lg-1">
                <div class="why-choose-text wow fadeInUp agree_sec" data-wow-duration="2s">
                    <div class="section-title agree">

                        <h2>AGREEMENT TO TERMS</h2>
                        <p>Appopay is here to provide you with the best experience of booking payments online. With this app, you do not have to worry about online payments  ahead of time. We provide a trustworthy solution to begin your journey.</p>
                    </div>

                    <div class="media">
                        <i class="flaticon-computer mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0"></h3>
                            Appopay is the next generation of digital wallets providing secure, real time transactions with multi-currency features. The Wallet not only stores your confidential information such as passwords, credit and debit cards, loyalty cards and other sensitive information securely it also gives you the ability to use your funds anyway you want without the security concerns of carrying cash in your pocket. By simply going online at www.appopay.com and opening a Appopay account and funding it, you have the convenience of secure omni-channel access 24/7 to your money any time anywhere. Appopay next generation of transactional technology brings together, in one application both online and through your mobile, all the functionality of Digital Wallets + Digital Banking, + Digital Payments + Digital Money Transfer. Your Appopay account gives you the ability to shop on line or in the store, have funds deposited in real time into your wallet through ACH, EFT, Credit or Debit Card or pickup money sent to you at agent locations across Latin America, pay bills one off or setup recurring payments plus top-up your or a family members mobile airtime, send money to friends and family through email money (with multicurrency availability), ACH, EFT, or at network sending locations. And for those times you need cash use your wallet to get cash at network ATM’s or at Partner and Agent locations.
                        </div>
                    </div>



                    <div class="media">
                        <i class="flaticon-wallet mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0"></h3>
                           The Wallet allows payments to be made from virtually anyone to anywhere in the world with multi-currency solutions including P2P, B2C, B2B, e-money, wireless and prepaid debit cards. If you have embraced debit cards as a convenient and secure alternative to cash Appopay has a solution for you as well. Appopay will issue you a prepaid debit card that can be tied directly to your Account. You can use your prepaid debit card to withdraw cash from any ATM or make purchases (pin-based transactions) from any merchant displaying the Appopay All cardholders can access their card balance and transaction history online at Appopay’s website – www.appopay.com Safety: Every time you hand over your credit/debit card or use net banking to pay, your identity and financial information is visible. However when you use Appopay to make your payment or purchase, your actual credit card numbers or bank details are never shared with merchants. Appopay provides a security barrier that can be in the form of passwords, biometrics or other security features. If your Wallet is compromised then the only amount of harm that’s possible is limited, as your bank account can’t be touched.
                        </div>
                    </div>

                    <div class="feature-text">

                        <div class="media-body ab_sec">
                            <h3 class="mt-0"></h3>

			 <ul class="aboutli">
                         <li><i class="flaticon-checked mr-2"></i>Easy installation and set-up</li>
     <li><i class="flaticon-checked mr-2"></i>Encrypted personal and financial data</li>
    <li><i class="flaticon-checked mr-2"></i> Seamless money transfer between accounts</li>
    <li><i class="flaticon-checked mr-2"></i> Use debit or prepaid cards,or bank account funds</li>
    <li><i class="flaticon-checked mr-2"></i> Share payments across multiple recipients</li>
     <li><i class="flaticon-checked mr-2"></i>Online and offline capability</li>
     <li><i class="flaticon-checked mr-2"></i>Cloud-based system</li>
     <li><i class="flaticon-checked mr-2"></i>Technical support through live chat, email or telephone</li>
                    </ul>
					  </div>
                    </div>



                    <div class="why-choose-btn">
                        <a routerLink="/app-landing" class="active">
                            <i class="flaticon-playstore"></i>
                            <p>Get It on</p>
                            <h5>Google Play</h5>
                        </a>

                        <a routerLink="/app-landing">
                            <i class="flaticon-app-store"></i>
                            <p>Available on the</p>
                            <h5>App Store</h5>
                        </a>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>




<section class="how-use pt-100">
    <div class="container">
        <div class="section-title">

            <h2>Competitive Edge</h2>

        </div>

        <div class="row  align-items-center">
            <div class="col-lg-6 ap">
                <div class="row align-items-center">

                    <div class="col-lg-12 col-md-6 col-sm-6 wow fadeInUp term_text" data-wow-duration="1s">
                        <p>APPOPAY partnership with our Financial Institutional Partners will leverage the Appopay integrated services and products to quickly gain mobile money market share. Appopay e-Wallet integrates with financial institutions back office software to enable the client’s customers to store, send, pay and receive payments worldwide. End users are in complete control of their money and transactions are quick, seamless, and safe. Mobile money accounts can be issued on demand by Mobile/Online and get SMS codes in country of operation,</p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6 wow fadeInUp term_text" data-wow-duration="1s">
                        <p>APPOPAY partnership with our Financial Institutional Partners will leverage the Appopay integrated services and products to quickly gain mobile money market share. Appopay e-Wallet integrates with financial institutions back office software to enable the client’s customers to store, send, pay and receive payments worldwide. End users are in complete control of their money and transactions are quick, seamless, and safe. Mobile money accounts can be issued on demand by Mobile/Online and get SMS codes in country of operation,</p>
                    </div>


                </div>
            </div>

            <div class="col-lg-4 " data-wow-duration="1s">

                        <div class="banner-img text-right">
                            <img src="assets/img/app-landing/abt.png" alt="iphone" style="height: 500px!important;max-width:600px!important;">
                        </div>

            </div>
        </div>
    </div>
</section>



<app-footer></app-footer>
