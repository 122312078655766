<nav class="navbar navbar-expand-md navbar-light navbar-area">
    <div class="container">
        <a class="navbar-brand" routerLink="/app-landing">
            <img src="assets/img/appopaynews.png" alt="logo" style="width:100px;">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">

                <li class="nav-item"><a class="nav-link active" routerLink="/app-merchant">Merchant</a></li>



                <li class="nav-item"><a class="nav-link" routerLink="/business">Business</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/agent">Agent</a></li>

                <li class="nav-item"><a class="nav-link" routerLink="/qrcode">QR Code</a></li>
                <li class="nav-item">

                    <div class="dropdown">
                        <div class="nav-link">Language
                            <i class="fa fa-caret-down"></i>
                        </div>
                        <div class="dropdown-content">

                <li> <a routerLink="/app-landing">English</a></li>
                <li> <a routerLink="/app-landing">Spanish</a></li>
                <li><a routerLink="/app-landing">Chinese</a> </li>


        </div>
    </div>

    </li>

    </ul>

    <div class="navbar-btn">
        <a href="" target="_blank"> Sign Up</a>
    </div>
    <div class="navbar-btn">
        <a href="https://appopay.com/appopayadmin/#/system/login" target="_blank">Customer Login</a>
    </div>
    </div>
    </div>
</nav>
<style>
    .columns {
        float: left;
        width: 33.33%;
        padding: 5px;
    }

    /* Clearfix (clear floats) */
    .rowa::after {
        content: "";
        clear: both;
        display: table;
    }

    .dropdown {
        float: left;
        overflow: hidden;
    }

    .dropdown {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding: 1px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
    }




    .dropdown-content {
        display: none;
        position: fixed;

        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        float: none;
        color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        background-color: #0899e7;
    }

    .dropdown-content a:hover {
        background-color: #f2f2f22e !important;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
</style>

<div class="w-100">
    <app-carousel></app-carousel>
</div>

<section class="how-use pt-100">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 ap_sec " data-wow-duration="1s">

                <div class="banner-img">
                    <img src="assets/img/app-landing/signin-Account.png" alt="iphone" class="banner_img2" style="margin-left: 82px;margin-top: 55px;height: 485px!important;max-width:460px!important;">
                </div>

    </div>
            <div class="col-lg-7 ap_box">
                <div class="row ">



                    <div class="col-lg-12 col-md-6 col-sm-6 ap " style="margin-top:15px;font-family: 'GlacialIndifferenceRegular';font-size:14pt;" >
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            <span style="font-size:22pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
                            <span style="font-size:22pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">y</span> is a next generation digital wallet super-app; providing secure, real time transactions with multi-currency features. The Wallet stores your confidential information such as passwords, credit and debit cards, loyalty cards and other sensitive information securely. Use your money how and where you want without the security concerns of carrying cash in your pocket. </p>
                    </div>



   <div class="col-lg-12 col-md-6 col-sm-6 ap"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
    <p style="margin-left: 10px; color: #000; font-weight: 500;">
        <span style="font-size:22pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
        <span style="font-size:22pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">y</span>
        <span style="font-size:14pt!important;color:#03a1de">'s</span> next generational transaction technology brings together the functionality of Digital Wallets + Digital Banking, + Digital Payments + Digital Money Transfers , all from one centralized app. Upon enrolling in the app, a personal bank account and virtual prepaid debit Union Pay International card are issued in your name. Use your UPI card to Shop online or in-store, have funds deposited into your wallet through Bank Account, Credit or Debit Card, Clave or agent locations across Latin America. Pay bills one off or with recurring payments from your mobile, plus top-up your, family or friends mobile airtime, send money to friends and family (with multicurrency availability and in real time), directly from the wallet or at Agent locations. For those times you need cash use your wallet to get cash at Agent locations or ATM machines with the<span style="font-size:22pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
        <span style="font-size:22pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">y</span> logo displayed.
      </p>
</div>


                </div>
            </div>


        </div>
    </div>
</section>





<section class="how-use pt-100">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 ap ">
                <div class="row align-items-center"
                    style=" font-size: 14pt;color: #000;font-family: 'GlacialIndifferenceRegular';">
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-one">
                            <span> 1</span>
                            <img class="mb-2" src="assets/img/app-landing/Installation.png" alt="icon">
                            <h3>Install</h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p> <b>Easily install</b> the app on your phone from the app store.Open the app, enter your phone number
                            and receive your account verification code.</p>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p>Scan your <b>government issued ID</b> to help us recognize you.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-two">
                            <span>2</span>
                            <img class="mb-2" src="assets/img/app-landing/set-your-profile.png" alt="icon">
                            <h3>Set Your Profile</h3>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-three">
                            <span>3</span>
                            <img class="mb-2" src="assets/img/app-landing/account.png" alt="icon">
                            <h3>Open Your Bank Account. </h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p>Read the Bank agreement, click <b>agree to the terms</b> and sign. </p>
                        <span> <b>ACCOUNT OPEN</b></span>

                    </div>


                </div>
            </div>
            <div class="col-lg-7 ap">
                <div class="row ">



                    <div class="col-lg-12 col-md-6 col-sm-6 ap " style="margin-top:15px;font-family: 'GlacialIndifferenceRegular';font-size:14pt;" >
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                         Download the <span style="font-size:22pt!important;color:#03a1de">a</span>
                         <span style="font-size:14pt!important;color:#03a1de">p</span>
                         <span style="font-size:14pt!important;color:#03a1de">p</span>
                         <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
                         <span style="font-size:22pt!important;color:#03a1de">p</span>
                         <span style="font-size:14pt!important;color:#03a1de">a</span>
                         <span style="font-size:14pt!important;color:#03a1de">y</span> app, enroll and upload youridentification documents. Automatically a CrediCorp U.S. Dollar Simple Bank Account is openedwith a virtual UPI card allowing you to shop in-store or online at over 52,000,000 locations around the world.  </p>
                    </div>



   <div class="col-lg-12 col-md-6 col-sm-6 ap "  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
    <p style="margin-left: 10px; color: #000; font-weight: 500;">
        Safety: Every time you hand over your credit/debit card or use net banking to pay, your identity and financial information is visible. However when you use <span style="font-size:22pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
        <span style="font-size:22pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">y</span> to make your payment or purchase, your actual credit card numbers or bank details are never shared with merchants. <span style="font-size:22pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
        <span style="font-size:22pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">y</span> uses tokenization as a security barrier. If your Wallet is compromised then the only amount of loss that is possible is limited, as your bank account and virtual card information cannot be touched.
      </p>
</div>


                </div>
            </div>


        </div>
    </div>
</section>




<app-footer></app-footer>
