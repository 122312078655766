<nav class="navbar navbar-expand-md navbar-light navbar-area pt-100 pb-50" style="background-color: #079ee8;">
    <div class="container">
        <a class="navbar-brand" routerLink="/app-landing">
            <img src="assets/img/appopaynews.png" alt="logo" style="width:100px;">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link " routerLink="/app-landing">Home</a></li>

                <li class="nav-item"><a class="nav-link " routerLink="/app-merchant">Merchant</a></li>

                <li class="nav-item"><a class="nav-link" routerLink="/agent">Agent</a></li>



    <li class="nav-item">

        <div class="dropdown">
            <div class="nav-link" routerLink="/processing">Language
                <i class="fa fa-caret-down"></i>
            </div>
            <div class="dropdown-content">

    <li> <a  routerLink="/app-landing">English</a></li>
    <li> <a routerLink="/app-landing">Spanish</a></li>
    <li><a routerLink="/app-landing">Chinese</a> </li>


    </div>
    </div>

    </li>

    </ul>

    <div class="navbar-btn">
        <a href="https://appopay.com/appopayadmin/#/system/login" target="_blank"> Customer Login</a>
    </div>
    <div class="navbar-btn">
        <a href="https://appopay.com/merchantadmin/#/system/login" target="_blank">Merchant Login</a>
    </div>
    </div>
    </div>
</nav>
<style>
    .dropdown {
        float: left;
        overflow: hidden;
    }

    .dropdown {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding: 1px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
    }




    .dropdown-content {
        display: none;
        position: fixed;

        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        float: none;
        color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        background-color: #0899e7;
    }

    .dropdown-content a:hover {
        background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
</style>

<!--div class="w-100">
    <app-carousel></app-carousel>
</div>-->

<section class=" pt-100"style="top:100px;margin-top: 73px;">
    <div class="container">
        <div class="section-title text-center">
          <!-- <span>Solutions</span>
            <h2>Payment Gateway Features</h2>-->

        </div>

        <div class="row ">

            <div class="col-lg-12">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/qrcodes-landing/qrcodelanding1112.png" alt="why choose image">
                </div>
            </div>
        </div>
    </div>
    </section>

<section class="pt-30" style="margin-top: 25px;">
    <div class="container">


        <div class="row ">

            <h2 class="mt-2 mb-3" style="font-size:30pt;font-weight:700;">QR Codes</h2>

        </div>
        <div>
            <img style="float:right;height:150px;width:150px;" src="assets/img/qrcodes-landing/globe.png"
                alt="why choose image">
        </div>


        <div class="col-lg-12 qr_code">



            <p class="mt-2 mb-3" ><a  href="#qrcode1" class="load-content">1. QR Code Introduction</a> </p>
            <p class="mt-2 mb-3"><a href="#qrcode2">2. What exactly is a QR code and where did they come from</a></p>
            <p class="mt-2 mb-3"><a href="#qrcode3">3. Types of QR Codes – Static</a></p>
            <p class="mt-2 mb-3"><a href="#qrcode4">4. Types of QR Codes – Dynamic</a> </p>
            <p class="mt-2 mb-3"><a href="#qrcode5">5. QR Codes and Marketing</a> </p>
            <p class="mt-2 mb-3"><a href="#qrcode6"> 6. QR Code Payment</a></p>

        </div>
    </div>

</section>



<section class="how-use" id="qrcode1">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 ap_sec" data-wow-duration="1s">

                <div class="banner-img">
                    <img src="assets/img/qrcodes-landing/qrcode2.png" alt="iphone"
                        style="margin-top: 30px;height: 355px!important;max-width:350px!important;">
                </div>

            </div>
            <div class="col-lg-7 ap_box">
                <div class="row ">



                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            It is hard to go anywhere today without seeing the iconic black and white square design of a
                            QR code. With the increased use of smartphones and the growing access of the internet, the
                            adoption of the versatile QR code seems to have taken over the world. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            In 2020, 1.5 billion people worldwide used QR codes to make a payment as usage soared during
                            the Covid-19 pandemic. By 2025, it is estimated, as many as 30% of all mobile users will pay
                            with QR codes, according to Juniper Research. Recent data from MasterCard found that demand
                            for contactless payments soared a remarkable 79% as consumers around the world embraced new
                            levels of sanitary and safety measures. Visa also reported the shift away from traditional
                            payment methods such as chip/PIN bankcards and cash has resulted in nearly a 20% jump in
                            small to midsize businesses acceptance of new digital forms of payments since June 2020.
                        </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            QR Code use is even bigger in China, where QR code payments have been popular since 2010 and
                            account for one-third of all payments today, amounting to USD $1.65 trillion in QR code
                            sales. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            QR Codes are so versatile that business use them for both marketing and payments resulting in
                            enhanced analytics and customer experiences. </p>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>


<section class=" why-choosenew pt-30 " id="qrcode2">
    <div class="container">


        <div class="row ">

            <div class="col-lg-8 ap_sec">

               <div class="row ">

                    <div class="col-lg-12 col-md-6 col-sm-6 ap"
                        style="margin-left:20px;font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <h6 style=" margin-left:20px;color: #000; font-weight: 700;font-size: 16pt;">What exactly is a
                            QR Code and where did they come from?</h6>
                    </div>


                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            QR or Quick Response Code, is a Code that is quickly readable by a cell phone or QR code
                            reader (hence the word “quick” in the name). QR Codes are similar to a bar code except they
                            are more advanced as they are two-dimensional and can be read both horizontally and vertically and are able
                            to store a great deal more information in various forms, unlike barcodes, that only provides
                            the numeric value of a product. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            This 2-D coding allows QR’s to carry 100 times more information,up to 7089 digits or 4296
                            characters, including punctuation marks and special characters, the Code can encode or
                            encrypt information and safely connect to payment processors. Even when damaged, the QR
                            Code’s structure data keys include duplications. It is due to these redundancies that allows
                            up to 30% of the Code structure to take damage without affecting its readability on
                            scanners. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            QR Codes are arranged with a pattern of black squares on a square grid with a white
                            background. Another distinct feature differentiating QR Codes from linear barcodes is their
                            ability to be scanned from either paper or screen. Linear barcodes can only be scanned from
                            paper, making QR codes much more versatile. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            Using a complex matrix of black and white squares a complex matrix of black and white squares they look like a
                            pixelated image, however each one of those squares is actually a marker serving a greater
                            function in the information-sharing capabilities of the Code.</p>
                    </div>


                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            Once scanned the code leads the scanner to a page or website that contains various
                            information about a subject or to the checkout process.</p>
                    </div>

                </div>

            </div>
            <div class="col-lg-3 ap_sec" style="left: 110px;">


                <div class="media">

                    <div class="media-body">
                        <div>
                            <img style="height:150px;width:250px;margin-left: -22px;"
                                src="assets/img/qrcodes-landing/barcode.png" alt="why choose image">
                        </div>

                        <div class="col-lg-12 col-md-6 col-sm-6"
                            style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                            <h6 style="color: #000; font-weight: 700;font-size: 16pt;">Original Barcode</h6>
                        </div>


                        <div style="font-family: 'GlacialIndifferenceRegular';font-size: 12pt;
                            line-height: 1rem;">

                            <p style="margin-left: auto; color: #000; font-weight: 500;">
                                <i class="fa fa-angle-double-right"></i>

                                &nbsp; One-dimensional, linear
                            </p>

                            <p style="margin-left: auto; color: #000; font-weight: 500;">
                                <i class="fa fa-angle-double-right"></i>

                                &nbsp; Only reads horizontally
                            </p>

                            <p style="margin-left: auto; color: #000; font-weight: 500;">
                                <i class="fa fa-angle-double-right"></i>

                                &nbsp; Only scanned from paper
                            </p>
                            <p style="margin-left: auto; color: #000; font-weight: 500;">
                                <i class="fa fa-angle-double-right"></i>

                                &nbsp; Holds a limited amount of information, 20-25 characters.
                            </p>


                        </div>

                        <div>
                            <img style="height:250px;width:250px;" src="assets/img/qrcodes-landing/qrcodeempty.png"
                                alt="why choose image">
                        </div>


                        <div class="col-lg-12 col-md-6 col-sm-6 mb-2"
                            style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
                            <h6 style="color: #000; font-weight: 700;font-size: 20pt;">QR Code</h6>
                        </div>
                        <div style="font-family: 'GlacialIndifferenceRegular';font-size: 12pt;
                        line-height: 1rem;">

                            <p style="margin-left: auto; color: #000; font-weight: 500;">
                                <i class="fa fa-angle-double-right"></i>

                                &nbsp; Two-dimensional
                            </p>

                            <p style="margin-left: auto; color: #000; font-weight: 500;">
                                <i class="fa fa-angle-double-right"></i>

                                &nbsp; Reads horizontally and Vertically
                            </p>

                            <p style="margin-left: auto; color: #000; font-weight: 500;">
                                <i class="fa fa-angle-double-right"></i>

                                &nbsp; Can be scanned from paper or screen.
                            </p>
                            <p style="margin-left: auto; color: #000; font-weight: 500;">
                                <i class="fa fa-angle-double-right"></i>

                                &nbsp; Holds up to7,089 alphanumeric characters.
                            </p>


                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="how-use pt-30 " >

    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 ap_sec " data-wow-duration="1s">

                <div class="banner-img">
                    <img src="assets/img/qrcodes-landing/person.png" alt="iphone"
                        style="margin-top: 10px;height: 305px!important;max-width:350px!important;">
                </div>

                <div class="col-lg-12 col-md-6 col-sm-6 mt-2 mb-3 ap "
                    style="font-family: 'GlacialIndifferenceRegular';font-size:18pt;">
                    <h6 style="color:#03a1de; font-weight: 700;font-size: 20pt;margin-left: 30px;">Masahiro Hara</h6>
                </div>
            </div>
            <div class="col-lg-7 ap_sec">
                <div class="row ">



                    <div class="col-lg-12 col-md-6 col-sm-6 ap ap_qr"
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            The invention of the QR Code can be contributed to the DENSO WAVE INC., by their lead
                            developer Masahiro Hara. Denso Wave Inc, was an industrial equipment manufacturer in the
                            Toyota Group. The company was doing research and development to streamline Toyota's Kanban
                            production method, also known as the just-in-time method. At the time, Denso used barcodes
                            to keep track of auto parts keep track of auto parts for shipping. However, barcodes can only convert around 29
                            alphanumeric characters', thus multiple bar codes were needed to handle all the information
                            required on the production line. This resulted in tens of thousands of scans during busy
                            times presenting major efficiency challenges to overcome, a better method had to be
                            developed. Hara began developing a new code that could contain a lot of information and
                            scanned efficiently. He set his sights on two-dimensional (2D) codes, whose development had
                            begun in the U.S. After some trial and error, Hara's QR codes were born in 1994.</p>
                    </div>



                </div>
            </div>


            <div class="col-lg-8 col-md-6 col-sm-6 mt-2 mb-3 ap "
                style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                <p style="margin-left: 10px; color: #000; font-weight: 500;">
                    By the beginning of the 20<sup>th</sup> century, QR codes had expanded beyond manufacturing to businesses,
                    especially marketers in retail spaces like grocery stores, department stores and pharmacies. Yet the
                    technology failed to get a solid footing. Twenty years ago, QR codes were before their
                    time. Smart phones were expensive and out of the reach of most. Wi-Fi access was restricted and there
                    were no QR Code scanner apps invented. QR codes showed great potential to the marketer but consumers
                    had very few ways to take advantage of the technology. That was the state of QR codes, two decades
                    ago. </p>
            </div>
        </div>
    </div>
</section>





<section class="how-use pt-30 ap_qr" >
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 ap_sec" data-wow-duration="1s">

                <div class="banner-img">
                    <img src="assets/img/qrcodes-landing/greenbarcode.png" alt="iphone"
                        style="margin-top: 30px;height: 485px!important;max-width:460px!important;">
                </div>

            </div>
            <div class="col-lg-7 ap_box">
                <div class="row ">



                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            Fast forward to 2017,when Apple's iOS 11 made it possible to read QR codes using its
                            standard camera app and nearly all other smartphone providers followed suit. With over 5
                            billion smart phone, users in 2018 marketers were quick to see the potential and jumped back
                            on the bandwagon, using the technology to offer interesting, helpful and just-plain-fun
                            offerings through QR codes on products. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            However, it wasn’t until 2020 and the global pandemic that QR Codes became mainstream, as
                            consumers wanted to avoid any form of physical contact while shopping, paying or interacting
                            with merchants. While there are other “touch-free” ways to pay, QR codes limit the
                            interactions that are associated with physical cards and NFC-based payments. Customers can
                            hold their phone to the QR code scanner at a reasonable distance and quickly complete the
                            payment there is no need to touch a POS terminal.
                        </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            QR codes provide product and service information fast and can be shared between devices
                            within a split second. They can be used to help track customer behavior through data analysis
                            and payments, providing better insights and convenience into the customer buying process.
                        </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            As far as privacy, QR code payments are very secure. Each code is randomly generated making
                            it unique to each customer, thereby ensuring the safety of personal information. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                          <b> It is true not all consumer behavior changes made because of the pandemic will be permanent, the shift however to contactless
                            payment is here to stay. Those who are slow to adopt the technology risk being left behind in terms of offering the
                            payment and marketing options customer’s desire.</b></p>
                    </div>

                </div>
            </div>


        </div>
    </div>
</section>




<section class=" why-choosenew pt-30" id="qrcode3">
    <div class="container">


        <div class="row ">

            <div class="col-lg-8 ap_sec">

                <div class="row ">

                    <div class="col-lg-12 col-md-6 col-sm-6 ap"
                        style="margin-left:20px;font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
                        <h6 style=" margin-left:20px;color: #000; font-weight: 700;font-size: 16pt;">Types of QR Codes
                            Static</h6>
                    </div>


                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            QR codes were originally a static design. The destination data is stored directly in the
                            Static QR, resulting in a larger, more dense and complex pattern. Because of pattern
                            density, it may require multiple scans to get all of the information read correctly. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            The content stored within a Static QR is fixed or permanent meaning it cannot be changed or
                            updated once generated. When a Static QR code is scanned, it will always show the same
                            content or destination address of the data or URL. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            A Static QR code is an autonomous entity and cannot be tracked, as it is not tied to any
                            service. If however, the static QR code is a URL, Google Analytics can be used to track the
                            URL itself.</p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            Static QR Codes are used in a variety of ways:</p>



                        <p style="margin-left: 30px; color: #000; font-weight: 500;">
                            <i class="fa fa-angle-double-right"></i>

                            &nbsp; Quick and simple online payment acceptance. Customers just have to scan the QR code,
                            enter the purchase amount, the merchant will verify the details and then the customer can
                            initiate the online payment.
                        <p style="margin-left: 30px; color: #000; font-weight: 500;">
                            <i class="fa fa-angle-double-right"></i>

                            &nbsp; Encoded URL provides a clickable link to a specific website or landing page.
                        </p>

                        <p style="margin-left: 30px; color: #000; font-weight: 500;">
                            <i class="fa fa-angle-double-right"></i>

                            &nbsp; Map display, provides link to specified location on Google Maps.
                        </p>
                        <p style="margin-left: 30px; color: #000; font-weight: 500;">
                            <i class="fa fa-angle-double-right"></i>

                            &nbsp; Direct Email, opens mail application with specified email address in recipient field.
                        </p>
                        <p style="margin-left: 30px; color: #000; font-weight: 500;">
                            <i class="fa fa-angle-double-right"></i>

                            &nbsp; WiFi connect, displays the WiFi name and password. End-users use the credentials to
                            log into the WiFi network.
                        </p>
                        <p style="margin-left: 30px; color: #000; font-weight: 500;">
                            <i class="fa fa-angle-double-right"></i>

                            &nbsp; Event Calendar, allows a user to save an events details to their phone calendar
                        </p>

                    </div>




                </div>

            </div>
            <div class="col-lg-3 ap_sec" style="left: 110px;top: 200px;">


                <div class="media">

                    <div class="media-body">
                        <div>
                            <img style="height:300px;width:300px;margin-left: -22px;"
                                src="assets/img/qrcodes-landing/unionpaybar.png" alt="why choose image">
                        </div>




                    </div>
                </div>
            </div>

        </div>
    </div>
</section>




<section class="how-use pt-100" id="qrcode4">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 ap_sec " data-wow-duration="1s">

                <div class="banner-img">
                    <img src="assets/img/qrcodes-landing/scannedme.png" alt="iphone"
                        style="margin-top: 30px;height: 450px!important;width:250px!important;">
                </div>

            </div>
            <div class="col-lg-7 ap_sec">
                <div class="row ">


                    <div class="col-lg-12 col-md-6 col-sm-6 ap"
                        style="margin-left:20px;font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
                        <h6 style=" margin-left:20px;color: #000; font-weight: 700;font-size: 16pt;">Types of QR Codes
                            Dynamic</h6>
                    </div>
                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            Unlike a Static QR Code where the more information you input the bigger and more complex the
                            structure becomes, a Dynamic Code’s content that is presented to a scanners is not directly
                            contained in the Code, but instead has a short redirection URL assigned to it. That means
                            the code remains small and is easier to integrate into print material and packaging design.
                        </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            The concept of dynamic QR Codes was first developed back in 2008 when QR Stuff Inc.
                            struggled with the dilemma of how to update or change information in a QR code that had
                            already been published. Since the principle behind QR codes is the pattern within the code’s
                            image is a direct representation of the data it contains and is the essence of the algorithm
                            that creates the QR code image. Therefore changing the data encoded into the QR code results
                            in changing the QR code image, which alters the QR message.
                        </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            The ability to edit QR codes comes not in storing the information within the QR code, but
                            rather to create a URL or webpage to hold the information and have the QR code direct the
                            scanner to the webpage where the information is held. This enables the editing to reside on
                            the webpage. While this does not actually make the QR code itself dynamic as the content
                            stays the same, but by putting a short URL into the QR code, it gives the impression that
                            the QR code can be changed.
                        </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            Dynamic QR codes greatly extend the useful life of a single QR code because once the QR code
                            has been published; the information can be changed at will without creating a new QR code
                            image. A single QR code image can be developed permanently and then simply re-tasked when
                            required – link it to a website this week, a YouTube video next week, a Facebook page the
                            week after that, or to different offer or coupon pages as each new promotional program is
                            released over time. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            While the solution was a simple yet innovative one, the positive effect Dynamic QR Codes
                            have, cannot be stress enough, especially in their roll in marketing</p>
                    </div>

                </div>
            </div>


        </div>
    </div>
</section>






<section class=" why-choosenew pt-100 pb-50 " id="qrcode5">
    <div class="container">


        <div class="row ">

            <div class="col-lg-8 ap">

                <div class="row ">

                    <div class="col-lg-12 col-md-6 col-sm-6 ap"
                        style="margin-left:20px;font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
                        <h6 style=" margin-left:20px;color: #000; font-weight: 700;font-size: 16pt;">QR Codes and
                            Marketing</h6>
                    </div>


                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            Getting your customers attention is everything in today’s instant gratification world.
                            Digital marketing has become more and more valuable in the effort to get customers engaged
                            wherever they are, online or at store. Regardless of your business type, streamlining the
                            consumers purchasing process and maintaining their attention is the best way to turn someone
                            browsing into someone buying. QR Code digital advertising gives customers the tools
                            necessary to quickly-and-easily do their own research, whether it is taking them to a product
                            page, a discount coupon or making the purchase. The ability to streamline action information
                            instantly can make all the difference. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            QR Codes stretches your advertising dollar! QR codes let you give your customers a large
                            amount of information, without overloading them with text so small they cannot read it, or
                            having to leave out half of your message. Regardless of your brand’s identity or packaging
                            design, a QR code can be made to accommodate it. While getting a large amount of information
                            to your customer in a small amount of space is important, it is but one of the many benefits
                            in using QR Codes in you marketing strategies. QR Codes can be linked to a Facebook or
                            Twitter page this week, a Business web page or website next week, a YouTube video the week
                            after that or to different coupon offers, surveys or event page as new programs and offers are
                            released over time. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            QR codes are an efficient way to translate a customer’s experience from the physical world
                            to the digital world, with a simple scan of a smartphone. The initial point when a customer
                            discovers something unique and interesting about a product or service is crucial. In the
                            past, customers had to remember that product, place, or piece of information that caught
                            their attention and follow up later, online or in person. Many forget or change their mind
                            during that gap. With QR codes, consumers are encouraged to instantly follow-up by
                            connecting with the product or service on social media, visiting a mobile site, filling out
                            a request for information, or downloading a coupon. QR code can be designed to encourage
                            instant action, get the customer involved now and provides the merchant an opportunity to
                            complete the sale.</p>
                    </div>




                </div>

            </div>
            <div class="col-lg-3 call " style="left: 110px;top: 200px;">


                <div class="media">

                    <div class="media-body">
                        <div>
                            <img style="height:300px;width:350px;margin-left: -22px;"
                                src="assets/img/qrcodes-landing/chekusout.png" alt="why choose image">
                        </div>




                    </div>
                </div>
            </div>

        </div>
    </div>
</section>





<section class="how-use pt-100">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 " data-wow-duration="1s">

                <div class="banner-img">
                    <img src="assets/img/qrcodes-landing/shoping.png" alt="iphone"
                        style="height:100%px!important;width:100%!important;">
                </div>

            </div>
            <div class="col-lg-7">
                <div class="row ">



                    <div class="col-lg-12 col-md-6 col-sm-6 qr qr-sec"
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style=" color: #000; font-weight: 500;">
                            Dynamic QR Codes provides valuable data such as the number of total scans, unique visitors;
                            average scans per day, location of scans, and the device type (iPhone/Android)
                            <br><br>A data-driven campaign strategy is necessary to understand how a QR marketing influences a
                            business success and reveals Return-On-Investment. With a solid, reliable tracking method,
                            it can:
                        </p>
                    </div>

                    <p style="margin-left: 16px; color: #000; font-weight: 500;">
                        <i class="fa fa-circle" style="font-size: 6pt !important;
                        color: #000;"></i>

                        &nbsp; Define target audiences.
                    </p>
                    <p style=" margin-left: 16px; color: #000; font-weight: 500;">
                        <i class="fa fa-circle" style="font-size: 6pt !important;
                            color: #000;"></i>

                        &nbsp; Analyze the way customers reached products, services, and digital platforms.
                    </p>
                    <p style=" margin-left: 16px; color: #000; font-weight: 500;">
                        <i class="fa fa-circle " style="font-size: 6pt !important;
                                color: #000;"></i>

                        &nbsp; Verify marketing effectiveness through traffic count.
                    </p>
                    <p style=" margin-left: 16px ; color: #000; font-weight: 500;">
                        <i class="fa fa-circle" style="font-size: 6pt !important;
                                    color: #000;"></i>

                        &nbsp; Identify traffic authenticity, set goals and expectations based on traffic history.
                    </p>
                    <p style=" margin-left: 16px; color: #000; font-weight: 500;">
                        <i class="fa fa-circle" style="font-size: 6pt !important;
                                        color: #000;"></i>

                        &nbsp; Determine what is working and what needs to be improved.
                    </p>
                    <p style="margin-left: 16px; color: #000; font-weight: 500;">
                        <i class="fa fa-circle" style="font-size: 6pt !important;
                                            color: #000;"></i>

                        &nbsp; Develop marketing campaigns based upon empirical evidence.
                    </p>

                    <div class="col-lg-12 col-md-6 col-sm-6 qr "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style=" color: #000; font-weight: 500;">
                            Data can be provided in real-time providing statistical information within minutes,
                            providing an up-to-date overview of the QR Code marketing strategy.
                        </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 qr "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style=" color: #000; font-weight: 500;">
                            Track total and unique scans. Total scans provides the number of times a QR code is scanned
                            dividing the total into one-time scans and repeat scans completed by the same device, giving
                            a clearer indication of how many people interacted with the marketing campaign.
                        </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 qr"
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style=" color: #000; font-weight: 500;">
                            Determine where your scans come from. Based on the IP address of the devise that scanned the
                            QR code statistical data is sorted to show the number of times the devised scanned the code
                            and the location, country and city. </p>
                    </div>


                </div>
            </div>


        </div>
    </div>
</section>

<section id="service" class="service-style-two pt-30 ">
    <div class="container">

        <div class="col-lg-12 col-md-6 col-sm-6 mb-3 ap"
            style="margin-left:20px;font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
            <h6 style=" margin-left:20px;color: #000; font-weight: 700;font-size: 24pt;">QR Codes Solutions for Business
            </h6>
        </div>

        <div class="row">


            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">URL QR </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        The QR code contains the address of a website. By scanning the code, the website can be access
                        by the user without the hassle of manually entering the address (URL).</p>

                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">SMS QR </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        The QR Code sends an SMS to a target phone number complete with a pre-filled text message.
                        <br><br><br>
                    </p>

                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">Plain Text QR </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        The QR Code displays a simple text consisting of up to 300 letters, numbers, and special
                        characters. No internet connection is required to work or scanned.</p>

                </div>
            </div>




            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">vCardQR </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        A vCard QR Code is the digital version of a business card and can easily be saved on a smart device. It displays a wide range of contact information. </p>
<br>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">Email QR  </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        Email QR code makes it easy for your customer to email you by automatically filling in your email address without having your customer type it in.
                        <br>
                    </p>

                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">Location QR  </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        Location QR codes show users the specific location of an establishment on Google Maps, Apple Maps, and Waze where they can get turn by turn details.</p>

                </div>
            </div>



            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">Coupon QR  </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        A digital coupon that is delivered by scanning a QR Code. Provide a wide range of discount offering or promotions such as buy one get on ½ off. </p>
<br>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">PDF QR   </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        PDF QR code is a convenient way of digitally sharing PDF documents. The scanner can save the PDF document to their phone for future use.
                        <br><br><br>
                    </p>

                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">APP Store QR   </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        An App Store QR Code when scanned connects the scanner directly to listed app stores to promote app download and installation.</p>
<br><br>
                </div>
            </div>



            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt20pt;text-align: center;">  Social Media QR   </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        Displays all of your social media channels on one page letting your customers choose how they want to follow and interact with your business.
                    </p>
<br>
                </div>
            </div>


            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">Ratings QR   </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        Scan the QR Code and leave a rating about your product or service. The Code can be tied directly into Google Reviews review page for your business.

                        <br>
                    </p>



                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">  Business Page QR  </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        Enables customers to go directly to your website  or other business landing page without having to type a URL into a browser on their mobile phones.
                    </p>
<br>
                </div>
            </div>







            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">    Feedback QR   </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        A feedback QR code allows you to collect your customers’ feedback right away and in real-time just by scanning the QR Code, where they are redirected to the feedback form online.

                    </p>
<br>
                </div>
            </div>



            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">Event QR    </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        Upon scanning the event QR code the viewer receives complete information about the Event and a registration button enabling them to register.


                        <br><br><br><br>
                    </p>






                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">   MP3 QR  </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        An MP3 QR code embeds audio into a QR code. Upon scanning users can directly listen to the track without the need of downloading the audio’s corresponding streaming app.

                    </p>
<br><br>
                </div>
            </div>







            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">  Video QR  </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        A Video QR Code, when scanned, the user can watch the encoded video online. Users no longer need to manually enter the video’s URL or search for a related link anymore.

                    </p>
<br>
                </div>
            </div>




            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;">  Image QR     </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        An image gallery QR code creates an interactive experience for your target audience and present multiple images right away in a single scan of a QR code.



                        <br><br>
                    </p>




                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <h6 style="color: #000; font-weight: 800;font-size: 20pt;text-align: center;"> Facebook QR  </h6>
                    <p
                        style="color: #000; font-weight: 500;font-family: 'GlacialIndifferenceRegular';text-align: center;">
                        A Facebook QR Code allows you to redirect users to your Facebook page to like or follow your business.This eliminates the need to type any URL into a browser.

                    </p>
<br>
                </div>
            </div>


        </div>
    </div>



</section>






<section class=" why-choosenew pt-30" id="qrcode6">
    <div class="container">


        <div class="row ">

            <div class="col-lg-8 ap_sec">

                <div class="row ">

                    <div class="col-lg-12 col-md-6 col-sm-6 ap"
                        style="margin-left:20px;font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
                        <h6 style=" margin-left:20px;color: #000; font-weight: 700;font-size: 16pt;">QR Code Payment</h6>
                    </div>


                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            The Covid-19 pandemic spurred many changes in consumer behavior — especially when it comes to how payments are made. The shift away from traditional payment methods such as chip/PIN bankcards and cash resulted in the rise of contactless payment solutions via mobile apps and NFC cards. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            As QR codes gain wide acceptance as the payment method of choice, they are one of the most advanced technologically and convenient contactless payment method in the market today. They are also one of the most efficient and simplest ways to complete online payments. </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            While there are several “touch-free” ways to pay, QR codes limits the interactions that are often associated with physical cards and NFC-based payments. Customers can hold their phone to the QR code scanner at a reasonable distance and complete the transactions fast, there is no need to touch a POS terminal or anything of the merchants. In terms of privacy, QR code-based payments are extremely secure as each code is randomly generated making it unique, ensuring the customers personal information is safe.</p>
                    </div>


                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                    style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                    <p style="margin-left: 10px; color: #000; font-weight: 500;">
                        From a business perspective, QR codes enhances the customer checkout experience, which is often a friction point with customers. Unlike NFC-based payments, the technology needed to accept QR codes is typically software-based instead of hardware-based making it quick to implement and user-friendly for both employees and customers.</p>
                </div>

                </div>

            </div>
            <div class="col-lg-3 ap_sec" style="left: 110px;">


                <div class="media">

                    <div class="media-body">
                        <div style="top: 200px;">
                            <img style="height:300px;width:350px;margin-left: -22px;"
                                src="assets/img/qrcodes-landing/scan1.png" alt="why choose image">
                        </div>

                        <div style="margin-top: 50px;">
                            <img style="height:300px;width:350px;margin-left: -22px;"
                                src="assets/img/qrcodes-landing/scan2.png" alt="why choose image">
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>
</section>



<section class="how-use pt-100">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 " data-wow-duration="1s">


                    <div class="banner-img" style="top: 100px;">
                        <img style="height:100%;width:100%;"
                            src="assets/img/qrcodes-landing/Picture1new.jpg" alt="why choose image">
                    </div>

                    <div class="banner-img" style="margin-top: 50px;">
                        <img style="height:100%;width:100%;"
                            src="assets/img/qrcodes-landing/Picture2.jpg" alt="why choose image">
                    </div>

            </div>
            <div class="col-lg-7 qr qr_taxi">
                <div class="row ">



                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            Part of the appeal of QR codes is that they make payments accessible for a wide range of businesses across different industries. Boutiques, taxi services, bars and restaurants and more have a lot to gain from adopting this exciting and relatively new technology.
                        </p>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 ap "
                        style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            Benefits of adopting QR code payments:
                        </p>
                    </div>


                    <p style=" color: #000; font-weight: 500;">
                       1.	Simple, cost-effective setup: All that is needed to accept QR code payments is a smartphone, no POS terminal required. The QR code takes the customer to a website to complete the payments.
                    </p>
                    <p style=" color: #000; font-weight: 500;">
                    	2.	Typically, QR code payments come with lower fees than card-not-present (CNP) transactions. This is especially helpful when a customer has forgotten their credit card or wallet. Sometimes, fees are even lower than card-present.
                     </p>
                     <p style=" color: #000; font-weight: 500;">
                        3.	QR codes can take payments from more than one customer at once, as the customer scans the code and puts in their payment themselves or automated scanning the app with their phone.
                     </p>


                     <p style=" color: #000; font-weight: 500;">

                        4.	QR codes are one of the safest payment methods. QR codes are tokenized and encrypted, making payment details less vulnerable to theft or hacking. Moreover, the processing takes place on the credit card company network directly for a more stable transaction.
                    </p>
                     <p style=" color: #000; font-weight: 500;">
                        5.	QR codes bridges the gap between in-store and digital, allowing customers to pay with their preferred digital wallets simply by pointing their phone at the code. This removes friction from the overall customer experience and bridges the gap between the real and the virtual worlds – this seamlessness is exactly what today’s consumers expect.
                     </p>



                </div>
            </div>


        </div>
    </div>
</section>






<section class="how-use pt-20 ap_qr">
    <div class="container">

        <div class="row  align-items-center">


            <div class="col-lg-12 ap ap_qr">
                <div class="row ">



                    <div class="col-lg-12 col-md-6 col-sm-6 ap " style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;" >
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">As Covid-19 concerns ease,<b> QR code payments are here to stay </b>, as they limit physical contact provide a secure, cost-effective and faster method to accept payments.</p>

                        <p style="margin-left: 10px; color: #000; font-weight: 500;"> If you are interested in adopting QR Code payments for your business,   <span style="font-size:22pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
                            <span style="font-size:22pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">y</span> works with merchants to streamline their payment offering across all channels, <span style="font-size:18pt!important;color:#03a1de">online</span> , <span style="font-size:18pt!important;color:#03a1de">in-app</span> and <span style="font-size:18pt!important;color:#03a1de">in-store</span>, and to scale their operations domestically and cross-border.</p>

                    </div>





                </div>
            </div>


        </div>
    </div>
</section>

<section class="how-use pt-20 pb-70"style="padding-bottom: 80px;">


<div class="newsletter">

        <div class="newsletter-area " style="background: #069fe9;border-radius: 0px!important;">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="newsletter-text" style="text-align: center;">
                        <h3>Contact Us</h3>
                        <p>We would love to here from you.</p>
                    </div>
                </div>


            </div>




</div>


</div>
</section>


<section id="apps" class="our-apps " style="padding-bottom: 220px;">



    <div id="contact" class="contact-section ">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 ">
                    <div class="contact-form">
                        <h2 class="text-center">Contact Sales</h2>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" placeholder="First Name *">
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" placeholder="Last Name *">
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" placeholder=" Email *">
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" placeholder="Company Name *">
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" placeholder="Phone Number *">
                                    </div>
                                </div>

                                <div class="col-md-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Country *">
                                    </div>
                                </div>
                                <div class="col-md-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Industry *">
                                    </div>
                                </div>
                                <div class="col-md-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Interested in *">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn contact-btn text-center">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>

<app-footer></app-footer>
