<nav class="navbar navbar-expand-md navbar-light navbar-area">
    <div class="container logo_img">
        <a class="navbar-brand" href="#" routerLink="/app-landing">
            <img src="assets/img/integratedsollogo.png" alt="logo" style="width:250px;">
        </a>



        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>


        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">

                <li class="nav-item"><a class="nav-link " routerLink="/app-landing">{{ 'demo.appmerchant1' | translate }}</a></li>

                <li class="nav-item"><a class="nav-link" routerLink="/gateway-services">{{ 'demo.appmerchant2' | translate }}</a></li>

                <li class="nav-item"><a class="nav-link" routerLink="/processing">{{ 'demo.appmerchant3' | translate }}</a></li>

                <li class="nav-item">

                    <div class="dropdown">
                        <div class="nav-link" >{{ 'demo.appmerchant4' | translate }}
                            <i class="fa fa-caret-down"></i>
                        </div>
                        <div class="dropdown-content">

		 <li> <a [routerLink]="" (click)="useLanguage('en')" >English</a></li>
                <li> <a [routerLink]="" (click)="useLanguage('de')">Spanish</a></li>
                <li><a routerLink="/app-landing">Chinese</a> </li>
                </div>
                </div>

                </li>
            </ul>

            <div class="navbar-btn">
                <a href="https://appopay.com/merchantapplication/#/" target="_blank" style="  ">{{ 'demo.appmerchant5' | translate }}</a>
            </div>
            <div class="navbar-btn">
                <a  href="#"routerLink="/contact-us" style="  ">{{ 'demo.appmerchant6' | translate }}</a>
            </div>
        </div>
    </div>
</nav>

<style>
    .dropdown {
        float: left;
        overflow: hidden;
    }

    .dropdown {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding: 1px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
    }




    .dropdown-content {
        display: none;
        position: fixed;

        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        float: none;
        color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        background-color: #0899e7;
    }

    .dropdown-content a:hover {
        background-color: #f2f2f22e!important;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
</style>
<div id="home" class="main-banner banner-bg-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12">

                             <div class="app-image">
                            <div class="main-image" style="">

                                <img src="assets/img/bulba.png" style="margin-top:-52px;max-width: 80%!important;"class="wow fadeInUp" data-wow-delay="0.9s" alt="image">
                            </div>



                            <div class="circle-img">
                                <img src="assets/img/sliderlanding/circle.png" alt="image">
                            </div>
                        </div>
                        <!--  <div style="margin-top: 12.5rem !important;">
                            <img class="bulb" src="assets/img/bulba.png" alt="iphone">

                             <h1>Let us help you grow </h1>
                            <div class="banner-btn">
                                <a routerLink="/app-landing">Download App</a>
                                <a href="https://vimeo.com/58363288" class="banner-video popup-vimeo"><i class="flaticon-play-button"></i> Watch Video</a>
                            </div>
                        </div>-->
                    </div>

                   <!-- <div class="col-lg-5">
                        <div class="banner-img text-right">
                            <img src="assets/img/bulb.png" alt="iphone">
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>

</div>




<section class="how-use pt-100 sec_1">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 " data-wow-duration="1s">

                <div class="banner-img text-right">
                    <img src="assets/img/merchantpage/Qrscanner1.jpg" alt="iphone" style="margin-top: 55px;max-width:500px!important;">
                </div>

    </div>
            <div class="col-lg-7">
                <div class="row ">

                        <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;margin-top: 25px;text-align: center;">
                            <img
                            src="assets/img/merchantpage/1.jpg" alt="case study image">
                        </div>
    <!--style="width: -moz-available!important;height:160px!important;"-->

                    <div class="col-lg-12 col-md-6 col-sm-6 " style="margin-top:15px;font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;" >
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">{{ 'demo.appmerchant7' | translate }}</p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;"> {{ 'demo.appmerchant8' | translate }}</p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
    <p style="margin-left: 10px; color: #000; font-weight: 500; line-height:1.4rem">{{ 'demo.appmerchant9' | translate }}
        <span style="font-size:22pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
        <span style="font-size:22pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">y</span> {{ 'demo.appmerchant10' | translate }} </p>
</div>
<div class="col-lg-12 col-md-6 col-sm-6"   style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
    <h6 style="margin-left: 10px; color: #000; font-weight: 700;font-size: 16pt;">{{ 'demo.appmerchant11' | translate }} </h6>
</div>


                </div>
            </div>


        </div>
    </div>
</section>







<section class="how-use pt-100 sec2">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 " data-wow-duration="1s">
                <div class="banner-img text-right">
                    <img src="assets/img/merchantpage/Laptop shopping.png" alt="iphone" style="margin-top: 55px;max-width:500px!important;">
                </div>

    </div>
            <div class="col-lg-7">
                <div class="row ">

                    <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;margin-top: 10px;text-align: center;">
                        <img
                        src="assets/img/merchantpage/2.jpg" alt="case study image">
                    </div>

                        <div class="col-lg-12 col-md-6 col-sm-6"   style="font-family: 'GlacialIndifferenceRegular';margin-top:15px;font-size:14pt;text-align: center;">
                            <h6 style="margin-left: 10px; color: #000; font-weight: 700;font-size: 16pt;"> {{ 'demo.appmerchant12' | translate }} </h6>
                        </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 " style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;" >
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                           {{ 'demo.appmerchant13' | translate }}
                            </p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;line-height: 1.4rem;">{{ 'demo.appmerchant14' | translate }} <span style="font-size:22pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
                            <span style="font-size:22pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">y</span> {{ 'demo.appmerchant15' | translate }}</p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
    <p style="margin-left: 10px; color: #000; font-weight: 500;line-height: 1.4rem;">{{ 'demo.appmerchant16' | translate }}        <span style="font-size:22pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
        <span style="font-size:22pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">y</span> {{ 'demo.appmerchant17' | translate }}
  </p>
</div>

<div class="col-lg-12 col-md-6 col-sm-6 " style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;" >
    <p style="margin-left: 10px; color: #000; font-weight: 500;">
       {{ 'demo.appmerchant18' | translate }}
        </p>
</div>


<div class="col-lg-12 col-md-6 col-sm-6 " style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;" >
    <p style="margin-left: 10px; color: #000; font-weight: 500;">
       {{ 'demo.appmerchant19' | translate }}
        </p>
</div>


                </div>
            </div>


        </div>
    </div>
</section>





<section class="how-use pt-100 sec3">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 " data-wow-duration="1s">
                <div class="banner-img text-right">
                    <img src="assets/img/merchantpage/Circle.png" alt="iphone" style="margin-top: 55px;height: 100% !important;width: 100% !important;">
                </div>

    </div>
            <div class="col-lg-7">
                <div class="row ">

                    <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;margin-top: 10px;text-align: center;">
                        <img style="width: -moz-available!important;"
                        src="assets/img/merchantpage/3.jpg" alt="case study image">
                    </div>

                        <div class="col-lg-12 col-md-6 col-sm-6"   style="font-family: 'GlacialIndifferenceRegular';margin-top:15px;font-size:14pt;">
                            <h6 style="margin-left: 20px; color: #000; font-weight: 700;font-size: 16pt;">{{ 'demo.appmerchant20' | translate }}   </h6>
                            <h5 style="margin-left: 10px; color: #000; font-weight: 700;font-size: 16pt;float:right;margin-right:75px;"> — {{ 'demo.appmerchant21' | translate }}  </h5>
                        </div>

                    <div class="col-lg-12 col-md-6 col-sm-6 " style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;" >
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            <i class="fa fa-arrow-right" style="font-size: 18pt !important;
                            color: #fb7d02;"></i>
                           {{ 'demo.appmerchant22' | translate }}
                            </p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">

    <p style="margin-left: 10px; color: #000; font-weight: 500;line-height: 1.4rem;">    <i class="fa fa-arrow-right" style="font-size: 18pt !important;
        color: #fb7d02;"></i><span style="margin-left: 10px;">{{ 'demo.appmerchant23' | translate }} </span>
          <span style="margin-left: 2px;">{{ 'demo.appmerchant24' | translate }} <span style="font-size:22pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
                            <span style="font-size:22pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">y</span> {{ 'demo.appmerchant25' | translate }}</span>
                            <span >   {{ 'demo.appmerchant26' | translate }}</span> </p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;">
   <p style="margin-left: 10px; color: #000; font-weight: 500;">    <i class="fa fa-arrow-right" style="font-size: 18pt !important;
                            color: #fb7d02;"></i>
                            <span style="margin-left: 10px;"> {{ 'demo.appmerchant27' | translate }} </span>
  </p>
</div>

<div class="col-lg-12 col-md-6 col-sm-6 " style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;" >
    <p style="margin-left: 10px; color: #000; font-weight: 500;">
        <i class="fa fa-arrow-right" style="font-size: 18pt !important;
        color: #fb7d02;"></i> <span> {{ 'demo.appmerchant28' | translate }}</span>
        </p>
</div>


<div class="col-lg-12 col-md-6 col-sm-6 " style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;" >
    <p style="margin-left: 10px; color: #000; font-weight: 500;line-height: 1.4rem;">
        <i class="fa fa-arrow-right" style="font-size: 18pt !important;
        color: #fb7d02;"></i> {{ 'demo.appmerchant29' | translate }}
        <span style="font-size:22pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">p</span>
        <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
        <span style="font-size:22pt!important;color:#03a1de">p</span>
        <span style="font-size:14pt!important;color:#03a1de">a</span>
        <span style="font-size:14pt!important;color:#03a1de">y</span> , {{ 'demo.appmerchant30' | translate }}
        </p>
</div>


                </div>
            </div>


        </div>
    </div>
</section>








<section class="how-use pt-100 sec4">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 " data-wow-duration="1s">
                <div class="banner-img text-right">
                    <img src="assets/img/merchantpage/analysiscell.png" alt="iphone" style="margin-top: 55px;max-width:500px!important;">
                </div>

    </div>
            <div class="col-lg-7">
                <div class="row ">

                    <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;margin-top: 10px;text-align: center;">
                        <img style="width: -moz-available!important;"
                        src="assets/img/merchantpage/7.jpg" alt="case study image">
                    </div>


                    <div class="col-lg-12 col-md-6 col-sm-6 " style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;" >
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            {{ 'demo.appmerchant31' | translate }}
                            </p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">{{ 'demo.appmerchant32' | translate }}
                            <span style="font-size:22pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
                            <span style="font-size:22pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">y</span> {{ 'demo.appmerchant33' | translate }}</p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
    <p style="margin-left: 10px; color: #000; font-weight: 500;">{{ 'demo.appmerchant34' | translate }}
  </p>
</div>

<div class="col-lg-12 col-md-6 col-sm-6 " style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;" >
    <p style="margin-left: 10px; color: #000; font-weight: 500;">
      {{ 'demo.appmerchant35' | translate }}
        </p>
</div>





                </div>
            </div>


        </div>
    </div>
</section>







<section class="how-use pt-100 sec5">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 " data-wow-duration="1s">
                <div class="banner-img text-right">
                    <img src="assets/img/merchantpage/rewards.jpg" alt="iphone" style="margin-top: 55px;max-width:500px!important;">
                </div>

    </div>
            <div class="col-lg-7">
                <div class="row ">

                    <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;margin-top: 10px;text-align: center;">
                        <img style="width: 700px!important;"
                        src="assets/img/merchantpage/4.jpg" alt="case study image">
                    </div>


                    <div class="col-lg-12 col-md-6 col-sm-6 " style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;" >
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            <span style="font-size:22pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
                            <span style="font-size:22pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">y</span> {{ 'demo.appmerchant36' | translate }} <br>{{ 'demo.appmerchant37' | translate }}
                            </p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;"><b>{{ 'demo.appmerchant38' | translate }}</b> {{ 'demo.appmerchant39' | translate }}</p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
    <p style="margin-left: 10px; color: #000; font-weight: 500;"><b>{{ 'demo.appmerchant40' | translate }}</b> {{ 'demo.appmerchant41' | translate }}
  </p>
</div>






                </div>
            </div>


        </div>
    </div>
</section>






<section class="how-use pt-100 pb-70 sec6">
    <div class="container">

        <div class="row  align-items-center">

            <div class="col-lg-5 " data-wow-duration="1s">
                <div class="banner-img text-right">
                    <img src="assets/img/merchantpage/Become an Agent1.jpg" alt="iphone" style="margin-top: 55px;max-width:500px!important;">
                </div>

    </div>
            <div class="col-lg-7">
                <div class="row ">

                    <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;margin-top: 10px;text-align: center;">
                        <img style="width:300px!important;height:150px;"
                        src="assets/img/merchantpage/5.jpg" alt="case study image">
                    </div>


                    <div class="col-lg-12 col-md-6 col-sm-6 " style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;" >
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">
                            <span style="font-size:22pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">p</span>
                            <span style="font-size:22pt!important;color:#fb7d02"><b>O</b></span>
                            <span style="font-size:22pt!important;color:#03a1de">p</span>
                            <span style="font-size:14pt!important;color:#03a1de">a</span>
                            <span style="font-size:14pt!important;color:#03a1de">y</span> {{ 'demo.appmerchant42' | translate }}
                            </p>
                    </div>

   <div class="col-lg-12 col-md-6 col-sm-6"  style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
                        <p style="margin-left: 10px; color: #000; font-weight: 500;">{{ 'demo.appmerchant43' | translate }}<br>
                            {{ 'demo.appmerchant44' | translate }}<br>
                          {{ 'demo.appmerchant45' | translate }}<br>
                           {{ 'demo.appmerchant46' | translate }}

                           </p>
                    </div>
                    <div class="col-lg-12 col-md-6 col-sm-6"   style="font-family: 'GlacialIndifferenceRegular';font-size:14pt;text-align: center;">
                        <h6 style="margin-left: 10px; color: #000; font-weight: 700;font-size: 16pt;"> {{ 'demo.appmerchant47' | translate }}</h6>
                    </div>







                </div>
            </div>


        </div>
    </div>
</section>


<!-- Start Contact Area -->
<section class="contact-area pb-70">
    <div class="container">
        <div class="col-lg-12 col-md-12 section-title">
            <h2> {{ 'demo.appmerchant48' | translate }}</h2>
            <div class="bar"></div>

        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <ul>



                       <li>
                            <div class="icon">
                                <i class="fa fa-map-marker"></i>
                            </div>
                            <span>{{ 'demo.appmerchant49' | translate }}</span>

                            Appopay ,Transistmeca alado pharmaciametro,Panama, Panama
                        </li>

                        <li>
                            <div class="icon">
                                <i class="fa fa-envelope"></i>
                            </div>
                            <span> {{ 'demo.appmerchant50' | translate }}</span>
                            <a href="#">support@appopay.com</a>

                        </li>

                        <li>
                            <div class="icon">
                                <i class="fa fa-phone"></i>
                            </div>
                            <span> {{ 'demo.appmerchant51' | translate }}</span>
                            <a href="#"> +507 373 1199 </a>

                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form  >
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">

                                    <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="{{ 'demo.appmerchant52' | translate }}"  ngModel>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="{{ 'demo.appmerchant50' | translate }}"  ngModel >
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="{{ 'demo.appmerchant51' | translate }}"  ngModel>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="{{ 'demo.appmerchant53' | translate }}"  ngModel>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6" required data-error="Write your message" placeholder="{{ 'demo.appmerchant54' | translate }}"  ngModel></textarea>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button  type="submit" class="btn btn-primary"> {{ 'demo.appmerchant55' | translate }} </button>
                                <div id="msgSubmit" class="h3 text-center hidden"></div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>


</section>
<app-footer></app-footer>
