<nav class="navbar navbar-expand-md navbar-light navbar-area">
    <div class="container">
        <a class="navbar-brand" routerLink="/app-landing">
            <img src="assets/img/appopaynews.png" alt="logo" style="width:100px;">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">

                <li class="nav-item"><a class="nav-link active" routerLink="/app-landing">Merchant</a></li>




    <li class="nav-item"><a class="nav-link" routerLink="/contact-us">Agent</a></li>

    <li class="nav-item"><a class="nav-link" routerLink="/contact-us">QR Code</a></li>
    <li class="nav-item">

        <div class="dropdown">
            <div class="nav-link" routerLink="/processing">Language
                <i class="fa fa-caret-down"></i>
            </div>
            <div class="dropdown-content">

    <li> <a  routerLink="/app-landing">English</a></li>
    <li> <a routerLink="/app-landing">Spanish</a></li>
    <li><a routerLink="/app-landing">Chinese</a> </li>


    </div>
    </div>

    </li>

    </ul>

    <div class="navbar-btn">
        <a href="https://appopay.com/appopayadmin/#/system/login" target="_blank"> Customer Login</a>
    </div>
    <div class="navbar-btn">
        <a href="https://appopay.com/merchantadmin/#/system/login" target="_blank">Merchant Login</a>
    </div>
    </div>
    </div>
</nav>
<style>
    .dropdown {
        float: left;
        overflow: hidden;
    }

    .dropdown {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding: 1px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
    }




    .dropdown-content {
        display: none;
        position: fixed;

        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        float: none;
        color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        background-color: #0899e7;
    }

    .dropdown-content a:hover {
        background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
</style>

<div id="home" class="main-banner main-banner-two banner-bg-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Manage Your Office Smarter & Faster</h1>
                    <p>A smart & Faster office is a workplace where technology enables people to work better, faster and, of course, smarter.</p>

                    <div class="banner-btn">
                        <a routerLink="/">Get Started</a>
                        <a href="https://vimeo.com/58363288" class="banner-video popup-vimeo"><i class="flaticon-play-button"></i> Watch Video</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-image-three">
        <img src="assets/img/sass-landing/laptop-three.png" alt="laptop">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>

    <div class="banner-shape-two">
        <img src="assets/img/sass-landing/shapes/1.png" alt="shape">
        <img src="assets/img/sass-landing/shapes/1.png" alt="shape">
    </div>
</div>

<section id="service" class="service-style-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>We Providing Our Best Services</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/1.png" alt="icon">
                    <h3>Business Marketing</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/2.png" alt="icon">
                    <h3>Expand SEO</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/3.png" alt="icon">
                    <h3>Office Management</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/4.png" alt="icon">
                    <h3>Client Managing</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/5.png" alt="icon">
                    <h3>Data Collection</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <img src="assets/img/sass-landing/service/6.png" alt="icon">
                    <h3>Secured System</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="service-shape">
        <img src="assets/img/map-four.png" alt="map shape">
        <img src="assets/img/sass-landing/shapes/3.png" alt="shape">
    </div>
</section>

<section id="features" class="features-style-two">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="feature-image">
                    <img src="assets/img/sass-landing/laptop.png" alt="laptop">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-text">
                    <div class="section-title text-left">
                        <h2>1000 of Customers Most Favourite Software</h2>
                        <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-checked"></i> Clean User Interface</li>
                        <li><i class="flaticon-checked"></i> Easy To Use</li>
                        <li><i class="flaticon-checked"></i> Best Performence</li>
                        <li><i class="flaticon-checked"></i> 24/7 Support</li>
                        <li><i class="flaticon-checked"></i> Free New Updates</li>
                        <li><i class="flaticon-checked"></i> Strong Secured Data Storage</li>
                    </ul>
                    <div class="feature-btn">
                        <a routerLink="/sass-landing-two">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="featues-shape">
        <img src="assets/img/sass-landing/shapes/4.png" alt="shape">
    </div>
</section>

<section class="keep-update">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="keep-updating-text">
                    <div class="section-title text-left">
                        <h2>Keep Business Updated on Phone</h2>
                    </div>
                    <p>ASTM International, formerly known as American Society for Testing and Materials, is an international standards organization that develops and publishes voluntary consensus technical standards for a wide range of materials, products, systems, and services.</p>

                    <div class="keep-btn">
                        <a routerLink="/sass-landing-two" class="active">
                            <i class="flaticon-playstore"></i>
                            <p>Get It on</p>
                            <h5>Google Play</h5>
                        </a>

                        <a routerLink="/sass-landing-two">
                            <i class="flaticon-app-store"></i>
                            <p>Available on the</p>
                            <h5>App Store</h5>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="keep-img">
                    <img src="assets/img/sass-landing/iphone-two.png" alt="iphone">
                </div>
            </div>
        </div>
    </div>

    <div class="keep-shape">
        <img src="assets/img/sass-landing/shapes/5.png" alt="shape">
    </div>
</section>

<section id="price" class="pricing-section price-style-two">
    <div class="container">
        <div class="section-title">
            <h2>Choose Your Affortable Price Plan</h2>
            <p>A comment is something that you say which expresses your opinion of something or which gives an explanation of it. If an event or situation.</p>
        </div>

        <div class="plan-list text-center">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item"><a class="active" id="month-tab" data-toggle="tab" href="#month" role="tab" aria-controls="month" aria-selected="true">Monthly Plan</a></li>

                <li class="nav-item"><a class="nav-link" id="annual-tab" data-toggle="tab" href="#annual" role="tab" aria-controls="annual" aria-selected="false">Annual Plan</a></li>
            </ul>
        </div>

        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="month" role="tabpanel" aria-labelledby="month-tab">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="price-card">
                            <div class="price">
                                <h3>$30 <span>/Month</span></h3>
                                <p>Basic Account</p>
                            </div>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-block"></i> No Free Trip</li>
                                    <li><i class="flaticon-block"></i> Membership Card</li>
                                    <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-block"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/sass-landing-two">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="price-card business-class active-price">
                            <div class="price">
                                <h3>$50 <span>/Month</span></h3>
                                <p>Business Class</p>
                            </div>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-checked"></i> No Free Trip</li>
                                    <li><i class="flaticon-checked"></i> Membership Card</li>
                                    <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-block"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/sass-landing-two">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div class="price-card premium">
                            <div class="price">
                                <h3>$100 <span>/Month</span></h3>
                                <p>Permium</p>
                            </div>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-checked"></i> No Free Trip</li>
                                    <li><i class="flaticon-checked"></i> Membership Card</li>
                                    <li><i class="flaticon-checked"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-checked"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/sass-landing-two">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="annual" role="tabpanel" aria-labelledby="annual-tab">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="price-card">
                            <div class="price">
                                <h3>$35 <span>/Annual</span></h3>
                                <p>Basic Account</p>
                            </div>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-block"></i> No Free Trip</li>
                                    <li><i class="flaticon-block"></i> Membership Card</li>
                                    <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-block"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/sass-landing-two">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="price-card business-class active-price">
                            <div class="price">
                                <h3>$99 <span>/Annual</span></h3>
                                <p>Business Class</p>
                            </div>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-checked"></i> No Free Trip</li>
                                    <li><i class="flaticon-checked"></i> Membership Card</li>
                                    <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-block"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/sass-landing-two">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div class="price-card premium">
                            <div class="price">
                                <h3>$150 <span>/Annual</span></h3>
                                <p>Permium</p>
                            </div>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-checked"></i> No Free Trip</li>
                                    <li><i class="flaticon-checked"></i> Membership Card</li>
                                    <li><i class="flaticon-checked"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-checked"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/sass-landing-two">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="price-shape">
        <img src="assets/img/sass-landing/shapes/8.png" alt="shap">
        <img src="assets/img/sass-landing/shapes/9.png" alt="shap">
        <img src="assets/img/sass-landing/shapes/10.png" alt="shap">
        <img src="assets/img/sass-landing/shapes/9.png" alt="shap">
    </div>
</section>

<div class="counter-style-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="counter-text">
                    <i class="flaticon-value"></i>
                    <h3><span class="counter">11</span>K</h3>
                    <p>Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="counter-text">
                    <i class="flaticon-rating"></i>
                    <h3><span class="counter">950</span>+</h3>
                    <p>Happy Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="counter-text">
                    <i class="flaticon-clipboard"></i>
                    <h3><span class="counter">5</span>+</h3>
                    <p>Pending Task</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="counter-text">
                    <i class="flaticon-rocket"></i>
                    <h3><span class="counter">1300</span>+</h3>
                    <p>Projects Done</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="testimonial" class="testimonial-style-two">
    <div class="container">
        <div class="section-title">
            <h2>People Quotes About Us</h2>
            <p>A comment is something that you say which expresses your opinion of something or which gives an explanation of it. If an event or situation.</p>
        </div>

        <div class="row">
            <div class="col-lg-7 offset-lg-3">
                <div class="testimonial-slider-area owl-carousel owl-theme">
                    <div class="testimonial-items">
                        <div class="client-info">
                            <img src="assets/img/app-landing/testimonial/client-img-three.png" alt="client">
                            <h6>John Doe</h6>
                            <p>Treaveller</p>
                        </div>
                        <p class="client-text">“I am really enjoying this app so far. I was sur rised that Shopify didn`t have a built in func ion for something like this.”</p>
                    </div>

                    <div class="testimonial-items">
                        <div class="client-info">
                            <img src="assets/img/app-landing/testimonial/client-img-two.png" alt="client">
                            <h6>Alice</h6>
                            <p>Developer</p>
                        </div>
                        <p class="client-text">“I am really enjoying this app so far. I was sur rised that Shopify didn`t have a built in func ion for something like this.”</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="newsletter  newsletter-style-two pt-100 pb-70">
    <div class="container">
        <div class="newsletter-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-text">
                        <h3>Subscribe Our Newsletter</h3>
                        <p>Your newsletter can provide great value, beyond sales, by informing your buyers with exciting content that resonates with them. So subscribe for our newsletter.</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="enter your email" name="EMAIL" required autocomplete="off">
                        <button class="default-btn electronics-btn" type="submit">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer-area footer-style-two footer-style-three">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <img src="assets/img/logo-two.png" alt="logo">
                    </div>
                    <p>A mobile application, also referred to as a mobile app or simply an app.</p>
                    <div class="footer-social">
                        <a target="_blank" href="#"><i class="flaticon-facebook"></i></a>
                        <a target="_blank" href="#"><i class="flaticon-twitter"></i></a>
                        <a target="_blank" href="#"><i class="flaticon-linkedin"></i></a>
                        <a target="_blank" href="#"><i class="flaticon-instagram"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Company</h3>

                    <ul>
                        <li><a href="#"><i class="flaticon-right"></i>Advertise</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Support</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Marketing</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Bonus</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><a href="#"><i class="flaticon-right"></i>Home</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Features</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Screenshots</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Pricing</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Testimonial</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Find Us</h3>

                    <ul>
                        <li><a href="tel:+02452142314"><i class="flaticon-right"></i> +0245 214 2314</a></li>
                        <li><a href="mailto:hello@euzan.com"><i class="flaticon-right"></i> hello@euzan.com</a></li>
                        <li><i class="flaticon-right"></i> 28/A Street, USA</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-4 lh-1">
                    <img src="assets/img/visa.png" alt="visa card">
                    <img src="assets/img/american-exp.png" alt="american-express">
                    <img src="assets/img/discover.png" alt="discover">
                    <img src="assets/img/master-card.png" alt="master card">
                </div>

                <div class="col-lg-6 col-md-8 lh-1">
                    <p>&copy;2020 Euzan. All Rights Reserved by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap.com</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="top-btn">
    <i class="flaticon-startup"></i>
</div>
