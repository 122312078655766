<footer class="footer-area" style="
background-image:linear-gradient(
            rgba(34, 34, 34, 0.6),
            rgba(34, 34, 34, 0.6)
          ),url('assets/img/footer_bg.png') ;
background-repeat: no-repeat;
background-size: 100%;
">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 foota">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <img src="assets/img/logo-white.png" alt="logo" style="width:200px;">
                    </div>

                    <div class="footer-link mb-3">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.stuffer.stuffers">
                            <img src="assets/img/google-play.png" alt="google image" style="height:35px;width:330px;">
                        </a>

                        <a target="_blank" href="https://apps.apple.com/app/stuffrs/id1609785260">
                            <img src="assets/img/app-store.png" alt="google image" style="height:35px;width:330px;">
                        </a>
                    </div>
                    <h6 style="color:#fff;font-weight:700;font-size:20px;">{{ 'demo.applanding229' | translate }}</h6>
                    <div class="footer-link mb-2">
                        <a routerLink="/app-landing">
                            <img src="assets/img/DSS-Logo.png" alt="google image" style="width:330px;">
                        </a>


                    </div>
                    <div class="footer-link mb-2">
                        <a routerLink="/app-landing">
                            <img src="assets/img/trust-seal-protected.png" alt="google image" style="width:330px;">
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 foot">
                <div class="footer-widget">
                    <h3>{{ 'demo.applanding234' | translate }}</h3>
                    <ul>
                        <li><a routerLink="/business"><i class="flaticon-right"></i> {{ 'demo.applanding230' | translate
                                }}</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> {{ 'demo.applanding231' | translate }}</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> {{ 'demo.applanding232' | translate }}</a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 footb">
                <div class="footer-widget">
                    <h3>{{ 'demo.applanding233' | translate }}</h3>
                    <ul>
                        <li><a routerLink=""><i class="flaticon-right"></i> {{ 'demo.applanding235' | translate }}</a>
                        </li>
                        <li><a routerLink="/privacypolicy"><i class="flaticon-right"></i> {{ 'demo.applanding236' |
                                translate }}</a></li>
                        <li><a target="_blank" href="#/termsofuse"><i class="flaticon-right"></i> {{
                                'demo.applanding237' | translate }}</a></li>
                        <li><a target="_blank" href="#/contact-us"><i class="flaticon-right"></i> {{
                                'demo.applanding238' | translate }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 footc">
                <div class="footer-widget">
                    <h3>{{ 'demo.applanding239' | translate }}</h3>
                    <ul>
                        <li><a href="tel:+5072168085"><i class="flaticon-right"></i>HEADQUARTERS:<br />
                                Calle Punta Darién, Punta Pacifica, P.H. Torre de las Américas, Suite 2905, Panamá City,
                                Panamá </a>
                            <br />
                            <a href="tel:+5072168085">+5072168085</a>
                        </li>
                        <!--                        <li><a href="tel:+5073731199"><i class="flaticon-right"></i>Phone:+5073731199</a></li>-->

                        <li><a href="tel:+1(829)637-0825"><i class="flaticon-right"></i>BRANCH:<br />
                                Calle Pedro Ignacio Espaillat 105, Gazcue, Santo Domingo, Dominican Republic</a>
                            <br />
                            <a href="tel:+1(829)637-0825">+1(829)637-0825</a>
                        </li>
                        <!--                        <li><a href="tel:+1(829)637-0825"><i class="flaticon-right"></i>Phone:+1(829)637-0825</a></li>-->

                        <li><a href="tel:+595134777"><i class="flaticon-right"></i>BRANCH:<br />
                                Asuncion,paraguay</a>
                            <br />
                            <a href="tel:+595134777">+595134777</a>

                        </li>
                        <!--                        <li><a href="tel:+595134777"><i class="flaticon-right"></i>Phone:+595134777</a></li>-->

                        <li><a href="mailto:hello@euzan.com"><i class="flaticon-right"></i>Email:support@appopay.com</a>
                        </li>
                        <li><a target="_blank" href="https://www.adofintech.org/miembros"><i
                                    class="flaticon-right"></i>Miembros | Cámara Fintech de Panamá</a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 footex">
                <div class="footer-widget">

                    <div class="footer-widget">
                        <!--                        <div class="footer-logo">-->
                        <!--                            <img src="assets/img/bank_logo2.png" alt="logo" style="width:200px;">-->
                        <!--                        </div>-->
                        <!--                        <h6 style="color:#fff;font-weight:600;">{{ 'demo.applanding240' | translate }}</h6>-->
                        <div class="footer-logo">
                            <img src="assets/img/visa-logo.png" alt="logo" style="width:125px;">
                            <img src="assets/img/bottomlogo.png" alt="logo" style="width:80px;">
                        </div>

                        <h6 style="color:#fff;font-weight:400;font-size: medium;">{{ 'demo.applanding241' | translate }}
                        </h6>


                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="position: relative;">
            <div class="footer-logo;col-lg-3 col-sm-6 footex">
                <img src="assets/img/sbp.png" alt="logo" style="width:200px;">
            </div>
            <div class="col-lg-9" >
                <p style="color:#fff;margin-top : 2%">STUFFRS S.A.(Appopay), es una empresa registrada y supervisada en materia de prevención de blanqueo decapitales por la
                     Superintendencia de Bancos de Panamá de acuerdo a los parámetros de la Ley N° 23 de 27 de abril de 2015. Ejerce actividades
                     como"Emisor de dinero electrónico y medios de pago" de acuerdo a las resoluciones SBP-PSO-R-2024-0031?
                </p>
            </div>
        </div>        
    </div>

    <div class="copyright-area" style="margin-top: 0%;">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-4 lh-1">
                    <img src="assets/img/bottomnew.png" alt="union card">
                    <img src="assets/img/visa.png" alt="visa card">
                    <img src="assets/img/american-exp.png" alt="american-express">
                    <img src="assets/img/discover.png" alt="discover">
                    <img src="assets/img/master-card.png" alt="master card">
                </div>

                <div class="col-lg-6 col-md-8 lh-1">
                    <p>Copyright @2024 appopay.com. All rights reserved <a href="https://appopay.com/appopaynew"
                            target="_blank"></a></p>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="top-btn">
    <i class="flaticon-rocket"></i>
</div>