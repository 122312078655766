<nav class="navbar navbar-expand-md navbar-light navbar-area">
    <div class="container">
        <a class="navbar-brand" routerLink="/app-landing">
            <img src="assets/img/appopaynews.png" alt="logo" style="width:100px;">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">

                <li class="nav-item"><a class="nav-link active" routerLink="/app-landing">Merchant</a></li>




    <li class="nav-item"><a class="nav-link" routerLink="/contact-us">Agent</a></li>

    <li class="nav-item"><a class="nav-link" routerLink="/contact-us">QR Code</a></li>
    <li class="nav-item">

        <div class="dropdown">
            <div class="nav-link" routerLink="/processing">Language
                <i class="fa fa-caret-down"></i>
            </div>
            <div class="dropdown-content">

    <li> <a  routerLink="/app-landing">English</a></li>
    <li> <a routerLink="/app-landing">Spanish</a></li>
    <li><a routerLink="/app-landing">Chinese</a> </li>


    </div>
    </div>

    </li>

    </ul>

    <div class="navbar-btn">
        <a href="https://appopay.com/appopayadmin/#/system/login" target="_blank"> Customer Login</a>
    </div>
    <div class="navbar-btn">
        <a href="https://appopay.com/merchantadmin/#/system/login" target="_blank">Merchant Login</a>
    </div>
    </div>
    </div>
</nav>

<style>
    .dropdown {
        float: left;
        overflow: hidden;
    }

    .dropdown {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding: 1px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
    }




    .dropdown-content {
        display: none;
        position: fixed;

        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        float: none;
        color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        background-color: #0899e7;
    }

    .dropdown-content a:hover {
        background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
</style>

<div id="home" class="main-banner banner-style-three banner-bg-five">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-text">
                            <h1>THE ULTIMATE CREATIVE AGENCY</h1>
                            <p>So what is a creative agency. It is a company that offers a combination of strategy, design, technology and advertising services to clients, it is creative or expert lead.</p>

                            <div class="banner-btn">
                                <a routerLink="/agency-landing-two" class="active">Get Started</a>
                                <a routerLink="/agency-landing-two">Contact Us</a>
                            </div>
                        </div>
                    </div>

                    <div class="banner-img-three">
                        <img src="assets/img/creative-agency/2.png" alt="consultant image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="about" class="about-us-section about-style-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image text-center">
                    <img src="assets/img/creative-agency/about-two.png" alt="about image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title text-left">
                        <span>About Us</span>
                        <h2>We Provide Excellent Business Solutions</h2>
                    </div>
                    <p>Landset is here to provide you with the best experience of creating a great venture. We help you to run your business with minimal tension.</p>
                    <ul>
                        <li><i class="flaticon-checked"></i> Creative Agency</li>
                        <li><i class="flaticon-checked"></i> 100% Trusted</li>
                        <li><i class="flaticon-checked"></i> 7 Years Experience</li>
                        <li><i class="flaticon-checked"></i> Top Ranking Agency</li>
                        <li><i class="flaticon-checked"></i> Best Support</li>
                    </ul>

                    <div class="about-btn">
                        <a routerLink="/agency-landing-two">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="about-shape">
        <img src="assets/img/map-two.png" alt="about shape">
    </div>
</section>

<section id="service" class="service-style-four pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Service</span>
            <h2>We are Providing Our Best Services</h2>
            <p>Landset is here to provide you with the best experience of creating a great venture. We help you to run your business with minimal tension.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-computer"></i>
                    <h3>Brand Identity</h3>
                    <p>Grow your identity in the big world of business with the help of our strategy and analysis.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-ui"></i>
                    <h3>UI/UX Design</h3>
                    <p>We provide a great user interface and user experience facility to improve the business website.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-web-programming"></i>
                    <h3>Development</h3>
                    <p>Offering incredible and highly functional business development that is entirely focused on clients.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-clipboard"></i>
                    <h3>Market Analysis</h3>
                    <p>We do the proper market analysis for you, we study your competitors and the state of your business.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-rocket"></i>
                    <h3>SEO Optimization</h3>
                    <p>With optimized SEO we ensure that you are ranked high in the search and reaching the max customer.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-pie-chart"></i>
                    <h3>Business Stategy</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="work-process pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Working Process</span>
            <h2>Have a Look Our Working Strategy</h2>
            <p>Our goal is to give you a high-class strategy for your great business idea and make it work step by step. Here is how we work.</p>
        </div>

        <div class="row">
            <div class="col-lg-2 col-sm-6 offset-lg-1">
                <div class="process-card">
                    <h2>1</h2>
                    <h3>Research</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6">
                <div class="process-card card-two">
                    <h2>2</h2>
                    <h3>Design</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6">
                <div class="process-card card-three">
                    <h2>3</h2>
                    <h3>Build</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6">
                <div class="process-card card-four">
                    <h2>4</h2>
                    <h3>Testing</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6 offset-sm-3 offset-md-3 offset-lg-0">
                <div class="process-card card-five">
                    <h2>5</h2>
                    <h3>Publish</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="case-study" class="case-study-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Some Great Works by Us</h2>
            <p>A comment is something that you say which expresses your opinion of something or which gives an explanation of it. If an event or situation.</p>
        </div>

        <div class="case-study-slider owl-carousel owl-theme">
            <div class="case-items">
                <div class="case-img">
                    <img src="assets/img/creative-agency/case-study/1.png" alt="case study image">

                    <div class="project-logo">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <img src="assets/img/creative-agency/case-study/logo-1.png" alt="logo">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="case-study-text">
                    <h3><a routerLink="/agency-landing-two">Ride Share App</a></h3>
                    <p>We have created an app with incorporation with the ideas of our client. With the help of this app, you can go to places that are cheaper than using a cab.</p>
                </div>
            </div>

            <div class="case-items">
                <div class="case-img">
                    <img src="assets/img/creative-agency/case-study/2.png" alt="case study image">

                    <div class="project-logo">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <img src="assets/img/creative-agency/case-study/logo-2.png" alt="logo">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="case-study-text">
                    <h3><a routerLink="/agency-landing-two">Rocket - New Marketing</a></h3>
                    <p>We helped a digital agency to grow its business with great strategy web designs and development. They help to grow your position in the market.</p>
                </div>
            </div>

            <div class="case-items">
                <div class="case-img">
                    <img src="assets/img/creative-agency/case-study/3.png" alt="case study image">

                    <div class="project-logo">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <img src="assets/img/creative-agency/case-study/logo-3.png" alt="logo">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="case-study-text">
                    <h3><a routerLink="/agency-landing-two">Freelancing Marketing</a></h3>
                    <p>Freelance marketers are enabling firms and companies to sell their products efficiently. They help their clients to a create a website/business platform.</p>
                </div>
            </div>

            <div class="case-items">
                <div class="case-img">
                    <img src="assets/img/creative-agency/case-study/1.png" alt="case study image">

                    <div class="project-logo">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <img src="assets/img/creative-agency/case-study/logo-1.png" alt="logo">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="case-study-text">
                    <h3><a routerLink="/agency-landing-two">Ride Share App</a></h3>
                    <p>We have created an app with incorporation with the ideas of our client. With the help of this app, you can go to places that are cheaper than using a cab.</p>
                </div>
            </div>

            <div class="case-items">
                <div class="case-img">
                    <img src="assets/img/creative-agency/case-study/2.png" alt="case study image">

                    <div class="project-logo">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <img src="assets/img/creative-agency/case-study/logo-2.png" alt="logo">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="case-study-text">
                    <h3><a routerLink="/agency-landing-two">Rocket - New Marketing</a></h3>
                    <p>We helped a digital agency to grow its business with great strategy web designs and development. They help to grow your position in the market.</p>
                </div>
            </div>

            <div class="case-items">
                <div class="case-img">
                    <img src="assets/img/creative-agency/case-study/3.png" alt="case study image">

                    <div class="project-logo">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <img src="assets/img/creative-agency/case-study/logo-3.png" alt="logo">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="case-study-text">
                    <h3><a routerLink="/agency-landing-two">Freelancing Marketing</a></h3>
                    <p>Freelance marketers are enabling firms and companies to sell their products efficiently. They help their clients to a create a website/business platform.</p>
                </div>
            </div>

            <div class="case-items">
                <div class="case-img">
                    <img src="assets/img/creative-agency/case-study/1.png" alt="case study image">

                    <div class="project-logo">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <img src="assets/img/creative-agency/case-study/logo-1.png" alt="logo">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="case-study-text">
                    <h3><a routerLink="/agency-landing-two">Ride Share App</a></h3>
                    <p>We have created an app with incorporation with the ideas of our client. With the help of this app, you can go to places that are cheaper than using a cab.</p>
                </div>
            </div>

            <div class="case-items">
                <div class="case-img">
                    <img src="assets/img/creative-agency/case-study/2.png" alt="case study image">

                    <div class="project-logo">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <img src="assets/img/creative-agency/case-study/logo-2.png" alt="logo">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="case-study-text">
                    <h3><a routerLink="/agency-landing-two">Rocket - New Marketing</a></h3>
                    <p>We helped a digital agency to grow its business with great strategy web designs and development. They help to grow your position in the market.</p>
                </div>
            </div>

            <div class="case-items">
                <div class="case-img">
                    <img src="assets/img/creative-agency/case-study/3.png" alt="case study image">

                    <div class="project-logo">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <img src="assets/img/creative-agency/case-study/logo-3.png" alt="logo">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="case-study-text">
                    <h3><a routerLink="/agency-landing-two">Freelancing Marketing</a></h3>
                    <p>Freelance marketers are enabling firms and companies to sell their products efficiently. They help their clients to a create a website/business platform.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="testimonial" class="testimonial-style-four">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h2>People Quotes About Us</h2>
            <p>A comment is something that you say which expresses your opinion of something or which gives an explanation of it. If an event or situation.</p>
        </div>

        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="testimonial-slider-wrapper owl-carousel owl-theme">
                    <div class="tsetimonial-card">
                        <img src="assets/img/creative-agency/testimonial/1.png" alt="client image">
                        <h3>John Doe</h3>
                        <span>Treaveller</span>
                        <p>I am really enjoying this app so far. I was surprised that Shopify didn`t have a built in function for something like this, but glad your app fits the bill.</p>
                    </div>

                    <div class="tsetimonial-card">
                        <img src="assets/img/creative-agency/testimonial/2.png" alt="client image">
                        <h3>Jack</h3>
                        <span>Consultant</span>
                        <p>I am really enjoying this app so far. I was surprised that Shopify didn`t have a built in function for something like this, but glad your app fits the bill.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="price" class="pricing-section price-style-three pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing Plan</span>
            <h2>Get Your Affortable Pricing Plan</h2>
            <p>We have the perfect plans for your given budgets. That’s why we brought you three kinds of price planning.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                    <div class="price-heading">
                        <h3>Personal Pack</h3>
                        <h2>$12 <span>/Month</span></h2>
                    </div>

                    <div class="price-feature">
                        <ul>
                            <li><i class="flaticon-checked"></i> Limited Trips</li>
                            <li><i class="flaticon-checked"></i> Available Trip History</li>
                            <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                            <li><i class="flaticon-checked"></i> Special Discount</li>
                            <li><i class="flaticon-block"></i> No Free Trip</li>
                            <li><i class="flaticon-block"></i> Membership Card</li>
                            <li><i class="flaticon-block"></i> Trip Cancellation</li>
                            <li><i class="flaticon-block"></i> Money Return</li>
                        </ul>
                    </div>

                    <div class="price-btn">
                        <a routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="price-card active business-class">
                    <div class="price-heading">
                        <h3>Business Class</h3>
                        <h2>$35 <span>/Month</span></h2>
                    </div>

                    <div class="price-feature">
                        <ul>
                            <li><i class="flaticon-checked"></i> Limited Trips</li>
                            <li><i class="flaticon-checked"></i> Available Trip History</li>
                            <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                            <li><i class="flaticon-checked"></i> Special Discount</li>
                            <li><i class="flaticon-checked"></i> No Free Trip</li>
                            <li><i class="flaticon-checked"></i> Membership Card</li>
                            <li><i class="flaticon-block"></i> Trip Cancellation</li>
                            <li><i class="flaticon-block"></i> Money Return</li>
                        </ul>
                    </div>

                    <div class="price-btn">
                        <a routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="price-card premium">
                    <div class="price-heading">
                        <h3>Permium</h3>
                        <h2>$50 <span>/Month</span></h2>
                    </div>

                    <div class="price-feature">
                        <ul>
                            <li><i class="flaticon-checked"></i> Limited Trips</li>
                            <li><i class="flaticon-checked"></i> Available Trip History</li>
                            <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                            <li><i class="flaticon-checked"></i> Special Discount</li>
                            <li><i class="flaticon-checked"></i> No Free Trip</li>
                            <li><i class="flaticon-checked"></i> Membership Card</li>
                            <li><i class="flaticon-checked"></i> Trip Cancellation</li>
                            <li><i class="flaticon-checked"></i> Money Return</li>
                        </ul>
                    </div>

                    <div class="price-btn">
                        <a routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Team Members</span>
            <h2>Our Creative Employee</h2>
            <p>Here is our dedicated team of Employee, who works creatively and relentlessly to bring you the best of the best service.</p>
        </div>

        <div class="team-slider owl-carousel owl-theme">
            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/1.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>Michle John</h3>
                            <p>Market Analysis</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/2.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>George</h3>
                            <p>Editor</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/3.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>Harry</h3>
                            <p>Consultant</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/4.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>Noah</h3>
                            <p>Researcher</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/1.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>Michle John</h3>
                            <p>Market Analysis</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/2.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>George</h3>
                            <p>Editor</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/3.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>Harry</h3>
                            <p>Consultant</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/4.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>Noah</h3>
                            <p>Researcher</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/1.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>Michle John</h3>
                            <p>Market Analysis</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/2.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>George</h3>
                            <p>Editor</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/3.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>Harry</h3>
                            <p>Consultant</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-card">
                <div class="team-img">
                    <img src="assets/img/creative-agency/team/4.jpg" alt="team member">

                    <div class="team-hover">
                        <div class="team-hover-text">
                            <h3>Noah</h3>
                            <p>Researcher</p>
                            <ul>
                                <li>
                                    <a href="#"><i class="flaticon-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="flaticon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="newsletter">
    <div class="container">
        <div class="newsletter-area newsletter-two">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-text">
                        <h3>Subscribe Our Newsletter</h3>
                        <p>Your newsletter can provide great value, beyond sales, by informing your buyers with exciting content that resonates with them.</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="enter your email" name="EMAIL" required autocomplete="off">
                        <button class="default-btn electronics-btn" type="submit">Subscribe</button>
                    </form>
                </div>
            </div>

            <div class="subscribe-shape">
                <img src="assets/img/shape/subscribe-shape.png" alt="shape">
                <img src="assets/img/shape/subscribe-shape-two.png" alt="shape">
                <img src="assets/img/shape/subscribe-shape-three.png" alt="shape">
            </div>
        </div>
    </div>
</div>

<footer class="footer-area footer-style-two footer-style-five">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <img src="assets/img/logo.png" alt="logo">
                    </div>
                    <p>We are always working to provide you the best business guidance.</p>
                    <div class="footer-social">
                        <a href="#" target="_blank"><i class="flaticon-facebook"></i></a>
                        <a href="#" target="_blank"><i class="flaticon-twitter"></i></a>
                        <a href="#" target="_blank"><i class="flaticon-linkedin"></i></a>
                        <a href="#" target="_blank"><i class="flaticon-instagram"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Company</h3>

                    <ul>
                        <li><a href="#"><i class="flaticon-right"></i> Advertise</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> Support</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> Marketing</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> Bonus</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><a href="#"><i class="flaticon-right"></i>Home</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Features</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Screenshots</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Pricing</a></li>
                        <li><a href="#"><i class="flaticon-right"></i>Testimonial</a>   </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Find Us</h3>

                    <ul>
                        <li><a href="tel:+02452142314"><i class="flaticon-right"></i> +0245 214 2314</a></li>
                        <li><a href="mailto:hello@euzan.com"><i class="flaticon-right"></i> hello@euzan.com</a></li>
                        <li><i class="flaticon-right"></i> 28/A Street, USA</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-4 lh-1">
                    <img src="assets/img/visa.png" alt="visa card">
                    <img src="assets/img/american-exp.png" alt="american-express">
                    <img src="assets/img/discover.png" alt="discover">
                    <img src="assets/img/master-card.png" alt="master card">
                </div>

                <div class="col-lg-6 col-md-8 lh-1">
                    <p>&copy;2020 Euzan. All Rights Reserved by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap.com</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="top-btn">
    <i class="flaticon-startup"></i>
</div>
