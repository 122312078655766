<nav class="navbar navbar-expand-md navbar-light navbar-area pt-100 pb-50"
     style="background-color: #079ee8;">
    <div class="container">
        <a class="navbar-brand" routerLink="/app-landing">
            <img src="assets/img/appopaynews.png" alt="logo" style="width:100px;">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link " routerLink="/app-landing">Home</a></li>


                <li class="nav-item"><a class="nav-link " routerLink="/app-merchant">Merchant</a>
                </li>


                <li class="nav-item"><a class="nav-link" routerLink="/agent">Agent</a></li>

                <li class="nav-item"><a class="nav-link" routerLink="/qrcode">QR Code</a></li>
                <li class="nav-item">

                    <div class="dropdown">
                        <div class="nav-link" routerLink="/processing">Language
                            <i class="fa fa-caret-down"></i>
                        </div>
                        <div class="dropdown-content">

                <li><a routerLink="/app-landing">English</a></li>
                <li><a routerLink="/app-landing">Spanish</a></li>
                <li><a routerLink="/app-landing">Chinese</a></li>


        </div>
    </div>

    </li>

    </ul>

    <div class="navbar-btn">
        <a href="https://appopay.com/appopayadmin/#/system/login" target="_blank"> Customer
            Login</a>
    </div>
    <div class="navbar-btn">
        <a href="https://appopay.com/merchantadmin/#/system/login" target="_blank">Merchant
            Login</a>
    </div>
    </div>
    </div>
</nav>
