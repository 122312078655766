<nav class="navbar navbar-expand-md navbar-light navbar-area">
    <div class="container">
        <a class="navbar-brand" routerLink="/app-landing">
            <img src="assets/img/appopaynews.png" alt="logo" style="width:100px;">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">

                <li class="nav-item"><a class="nav-link active" routerLink="/app-landing">Merchant</a></li>




    <li class="nav-item"><a class="nav-link" routerLink="/contact-us">Agent</a></li>

    <li class="nav-item"><a class="nav-link" routerLink="/contact-us">QR Code</a></li>
    <li class="nav-item">

        <div class="dropdown">
            <div class="nav-link" routerLink="/processing">Language
                <i class="fa fa-caret-down"></i>
            </div>
            <div class="dropdown-content">

    <li> <a  routerLink="/app-landing">English</a></li>
    <li> <a routerLink="/app-landing">Spanish</a></li>
    <li><a routerLink="/app-landing">Chinese</a> </li>


    </div>
    </div>

    </li>

    </ul>

    <div class="navbar-btn">
        <a href="https://appopay.com/appopayadmin/#/system/login" target="_blank"> Customer Login</a>
    </div>
    <div class="navbar-btn">
        <a href="https://appopay.com/merchantadmin/#/system/login" target="_blank">Merchant Login</a>
    </div>
    </div>
    </div>
</nav>
<style>
    .dropdown {
        float: left;
        overflow: hidden;
    }

    .dropdown {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding: 1px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
    }




    .dropdown-content {
        display: none;
        position: fixed;

        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        float: none;
        color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        background-color: #0899e7;
    }

    .dropdown-content a:hover {
        background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
</style>

<div id="home" class="main-banner banner-style-two banner-bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Make Your Business More Smarter</h1>
                    <p>The moment you focus on a goal, your goal becomes a magnet, pulling you with business and your resources toward it.</p>

                    <div class="banner-btn">
                        <a routerLink="/">Get Started</a>
                        <a href="https://vimeo.com/58363288" class="banner-video popup-vimeo"><i class="flaticon-play-button"></i> Watch Video</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-image-two">
        <img src="assets/img/sass-landing/laptop.png" alt="laptop">
        <img src="assets/img/sass-landing/iphone.png" alt="iphone">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>

    <div class="banner-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>
</div>

<section class="trusted-company">
    <div class="container">
        <div class="section-title text-center">
            <span>Trusted Companies</span>
            <h2>750+ Companies Trusted Us</h2>
        </div>

        <div class="company-slider owl-carousel owl-theme">
            <div class="company-items">
                <a href="#" target="_blank">
                    <img src="assets/img/sass-landing/company/1.png" alt="company logo">
                </a>
            </div>

            <div class="company-items">
                <a href="#" target="_blank">
                    <img src="assets/img/sass-landing/company/2.png" alt="company logo">
                </a>
            </div>

            <div class="company-items">
                <a href="#" target="_blank">
                    <img src="assets/img/sass-landing/company/3.png" alt="company logo">
                </a>
            </div>

            <div class="company-items">
                <a href="#" target="_blank">
                    <img src="assets/img/sass-landing/company/4.png" alt="company logo">
                </a>
            </div>

            <div class="company-items">
                <a href="#" target="_blank">
                    <img src="assets/img/sass-landing/company/3.png" alt="company logo">
                </a>
            </div>
        </div>
    </div>
</section>

<section id="service" class="service-section pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Services</span>
            <h2>We Providing Our Best Services</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-one wow fadeInUp" data-wow-duration="1s">
                    <i class="flaticon-pie-chart"></i>
                    <h3>Business Marketing</h3>
                    <p>Business marketing is a marketing practice of individuals or organizations.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-two wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                    <i class="flaticon-rocket"></i>
                    <h3>Expand SEO</h3>
                    <p>Euzan SEO is a profession involving the design, evaluation, and maintenance.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-three wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                    <i class="flaticon-folder"></i>
                    <h3>Office Management</h3>
                    <p>Euzan Office management is a profession involving the design, implementation and evaluation</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-four wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                    <i class="flaticon-relationship"></i>
                    <h3>Client Managing</h3>
                    <p>Client managing, that is relationship between company and customers.It's very important for company.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-five wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                    <i class="flaticon-cloud-computing"></i>
                    <h3>Data Collection</h3>
                    <p>Data collection is the process of gathering and measuring information on targeted variables.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-six wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
                    <i class="flaticon-verified"></i>
                    <h3>Secured System</h3>
                    <p>To secure a computer system, it is important to understand the attacks that can be made against it.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="why-choose why-choose-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span>Why Choose Us?</span>
            <h2>We Providing Best Qualities</h2>
            <p>We always provide our best qualities ever. Our best qualities are a clean user interface, subheading, easy to use.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/laptop.png" alt="why choose image">
                </div>
            </div>

            <div class="col-lg-5">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="1s">
                    <div class="media">
                        <i class="flaticon-computer mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Clean User Interface</h3>
                            A good user interface is well-organized, making it easy to locate different tools and options. Euzan provides you with a clean user interface.
                        </div>
                    </div>

                    <div class="media">
                        <i class="flaticon-layers mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Easy To Use</h3>
                            It is basically easy to use. And Euzan  describes an action or activity as easy, you mean that it is done in a confident, relaxed way.
                        </div>
                    </div>

                    <div class="media">
                        <i class="flaticon-countdown mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Best Performence</h3>
                            Euzan best Performance is the successful ending of a program required by the department, including an education, work, or other program.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shapes">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
            <img src="assets/img/shape/6.png" alt="shape">
            <img src="assets/img/shape/7.png" alt="shape">
        </div>
    </div>
</section>

<section class="standard-section pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="standard-text">
                    <div class="section-title text-left">
                        <span>Standard Code</span>
                        <h2>Generate By Standard Code</h2>
                    </div>
                    <p>With Euzan, code generation is the process by which a compiler's code generator converts some intermediate representation of source code into a form that can be readily executed by a machine.Sophisticated compilers typically perform multiple passes over various intermediate forms.</p>
                    <p>This multi-stage process is used because many algorithms for code optimization are easier to apply one at a time, or because the input to one optimization relies on the completed processing performed by another optimization.</p>

                    <div class="standard-btn">
                        <a routerLink="/">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="standard-img">
                    <img src="assets/img/sass-landing/laptop-two.png" alt="laptop">
                </div>
            </div>
        </div>

        <div class="standard-shape">
            <img src="assets/img/map-three.png" alt="shape">
        </div>
    </div>
</section>

<section id="apps" class="our-apps pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="our-apps-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/iphone.png" alt="iphone">
                </div>
            </div>

            <div class="col-lg-7">
                <div class="our-apps-text">
                    <div class="section-title text-left">
                        <span>Our Mobile Apps</span>
                        <h2>Also Available on Your Phone</h2>
                    </div>
                    <p>If you are on the phone, you have a phone in your home or place of work, so that you can be used  by phone. Euzan is also available on your phone.  Because of these, our website is looking stunning and on all types of screens and devices.</p>

                    <div class="our-apps-btn">
                        <a routerLink="/" class="active">
                            <i class="flaticon-playstore"></i>
                            <p>Get It on</p>
                            <h5>Google Play</h5>
                        </a>

                        <a routerLink="/">
                            <i class="flaticon-app-store"></i>
                            <p>Available on the</p>
                            <h5>App Store</h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="apps-shape">
        <img src="assets/img/shape/1.png" alt="shape">
        <img src="assets/img/shape/3.png" alt="shape">
        <img src="assets/img/shape/5.png" alt="shape">
        <img src="assets/img/shape/6.png" alt="shape">
        <img src="assets/img/map-two.png" alt="shape">
    </div>
</section>

<div class="counter-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-9 offset-lg-1">
                <div class="row">
                    <div class="col-lg-2 col-6">
                        <div class="counter-text">
                            <i class="flaticon-value"></i>
                            <h2><span class="counter">11</span>K</h2>
                            <p>Clients</p>
                        </div>
                    </div>

                    <div class="col-lg-2 col-6">
                        <div class="counter-text">
                            <i class="flaticon-rating"></i>
                            <h2><span class="counter">950</span>+</h2>
                            <p>Happy Clients</p>
                        </div>
                    </div>

                    <div class="col-lg-2 col-6">
                        <div class="counter-text">
                            <i class="flaticon-clipboard"></i>
                            <h2><span class="counter">5</span>+</h2>
                            <p>Pending Task</p>
                        </div>
                    </div>

                    <div class="col-lg-2 col-6">
                        <div class="counter-text">
                            <i class="flaticon-rocket"></i>
                            <h2><span class="counter">1300</span>+</h2>
                            <p>Prjects Done</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-img wow" data-wow-duration="1s">
        <img src="assets/img/sass-landing/laptop.png" alt="laptop">
        <img src="assets/img/sass-landing/iphone.png" alt="laptop">
    </div>
</div>

<section id="pricing" class="pricing-section price-section-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing Plan</span>
            <h2>A Smart Pricing Plan for You</h2>
            <p>We are here with a smart pricing plan for you. We usually provide you with a monthly plan and annual plan.</p>
        </div>

        <div class="plan-list text-center">
            <ul class="nav nav-tabs" id="tab-one" role="tablist">
                <li class="nav-item"><a class="active" id="month-tab" data-toggle="tab" href="#month" role="tab" aria-controls="month" aria-selected="true">Monthly Plan</a></li>

                <li class="nav-item"><a class="nav-link" id="annual-tab" data-toggle="tab" href="#annual" role="tab" aria-controls="annual" aria-selected="false">Annual Plan</a></li>
            </ul>
        </div>

        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="month" role="tabpanel" aria-labelledby="month-tab">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="price-card">
                            <p>Personal Pack</p>
                            <h3>$12<span> /Month</span></h3>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-block"></i> No Free Trip</li>
                                    <li><i class="flaticon-block"></i> Membership Card</li>
                                    <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-block"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="price-card active-price business-class ">
                            <p>Business Class</p>
                            <h3>$35<span> /Month</span></h3>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-checked"></i> No Free Trip</li>
                                    <li><i class="flaticon-checked"></i> Membership Card</li>
                                    <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-block"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div class="price-card premium">
                            <p>Premium</p>
                            <h3>$50<span> /Month</span></h3>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-checked"></i> No Free Trip</li>
                                    <li><i class="flaticon-checked"></i> Membership Card</li>
                                    <li><i class="flaticon-checked"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-checked"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="annual" role="tabpanel" aria-labelledby="annual-tab">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="price-card">
                            <p>Personal Pack</p>
                            <h3>$30<span> /Annual</span></h3>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-block"></i> No Free Trip</li>
                                    <li><i class="flaticon-block"></i> Membership Card</li>
                                    <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-block"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="price-card active-price business-class ">
                            <p>Business Class</p>
                            <h3>$60<span> /Annual</span></h3>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-checked"></i> No Free Trip</li>
                                    <li><i class="flaticon-checked"></i> Membership Card</li>
                                    <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-block"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div class="price-card premium">
                            <p>Premium</p>
                            <h3>$90<span> /Annual</span></h3>

                            <div class="price-feature">
                                <ul>
                                    <li><i class="flaticon-checked"></i> Limited Trips</li>
                                    <li><i class="flaticon-checked"></i> Available Trip History</li>
                                    <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                    <li><i class="flaticon-checked"></i> Special Discount</li>
                                    <li><i class="flaticon-checked"></i> No Free Trip</li>
                                    <li><i class="flaticon-checked"></i> Membership Card</li>
                                    <li><i class="flaticon-checked"></i> Trip Cancellation</li>
                                    <li><i class="flaticon-checked"></i> Money Return</li>
                                </ul>
                            </div>

                            <div class="price-btn">
                                <a routerLink="/">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="testimonial" class="testimonial-section">
    <div class="container-fluid">
        <div class="section-title">
            <span>Testimonial</span>
            <h2>People Quotes About Us</h2>
            <p>We value what our valued clients and users have to say about us. We are always ready to improve and be at your service.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="testimonial-img">
                    <img src="assets/img/app-landing/map.png" alt="map image">

                    <div class="client-img">
                        <img src="assets/img/app-landing/testimonial/1.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/2.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/3.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/4.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/5.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/6.png" alt="client image">
                    </div>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <img src="assets/img/app-landing/testimonial/client-img.png" alt="client image">
                        <div class="client-info">
                            <h3>John Doe</h3>
                            <span>Traveler</span>
                        </div>
                        <p>I am enjoying this app so far. I was surprised that Shopify didn`t have a built-in function for something like this but glad your app fits the bill.</p>
                    </div>

                    <div class="testimonial-item">
                        <img src="assets/img/app-landing/testimonial/client-img-three.png" alt="client image">
                        <div class="client-info">
                            <h3>Harry</h3>
                            <span>SEO Expert</span>
                        </div>
                        <p>I am enjoying this app so far. I was surprised that Shopify didn`t have a built-in function for something like this but glad your app fits the bill.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonial-shape">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
        </div>
    </div>
</section>

<div class="newsletter">
    <div class="container">
        <div class="newsletter-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-text">
                        <h3>Subscribe Our Newsletter</h3>
                        <p>Your newsletter can provide great value, beyond sales, by informing your buyers with exciting content that resonates with them.</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="enter your email" name="EMAIL" required autocomplete="off">
                        <button class="default-btn electronics-btn" type="submit">Subscribe</button>
                    </form>
                </div>
            </div>

            <div class="subscribe-shape">
                <img src="assets/img/shape/subscribe-shape.png" alt="shape">
                <img src="assets/img/shape/subscribe-shape-two.png" alt="shape">
                <img src="assets/img/shape/subscribe-shape-three.png" alt="shape">
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
