import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import { I18nServiceService } from '../../../services/i18n-service.service';
@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  imageObject = [{
    image: 'assets/img/app-landing/Slider 1.jpg',
    thumbImage: 'assets/img/app-landing/Slider 1.jpg',
    title: 'Learn More.'
}, {
  image: 'assets/img/app-landing/virtualbankaccount.jpg',
  thumbImage: 'assets/img/app-landing/virtualbankaccount.jpg',
  title: 'Learn More.'
},
{
  image: 'assets/img/app-landing/virtualcard.jpg',
  thumbImage: 'assets/img/app-landing/virtualcard.jpg',
  title: 'Learn More.'
},
{
  image: 'assets/img/app-landing/p-p-transfer.jpg',
  thumbImage: 'assets/img/app-landing/p-p-transfer.jpg',
  title: 'Learn More.'
},
{
  image: 'assets/img/app-landing/billpayment.jpg',
  thumbImage: 'assets/img/app-landing/billpayment.jpg',
  title: 'Learn More.'
},
{
  image: 'assets/img/app-landing/myshopping.jpg',
  thumbImage: 'assets/img/app-landing/myshopping.jpg',
  title: 'Learn More.'
},
{
  image: 'assets/img/app-landing/emvqrcode.jpg',
  thumbImage: 'assets/img/app-landing/emvqrcode.jpg',
  title: 'Learn More.'
},];
  constructor(private translate: TranslateService,
              private i18nService: I18nServiceService) { }

  ngOnInit(): void {
  }
}
