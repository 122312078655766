<nav class="navbar navbar-expand-md navbar-light navbar-area pt-100 pb-50" style="background-color: #079ee8;">
    <div class="container">
        <a class="navbar-brand" routerLink="/app-landing">
            <img src="assets/img/appopaynews.png" alt="logo" style="width:100px;">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link " routerLink="/app-landing">Home</a></li>

                <li class="nav-item"><a class="nav-link " routerLink="/app-merchant">Merchant</a></li>




    <li class="nav-item"><a class="nav-link" routerLink="/qrcode">QR Code</a></li>
    <li class="nav-item">

        <div class="dropdown">
            <div class="nav-link" routerLink="/processing">Language
                <i class="fa fa-caret-down"></i>
            </div>
            <div class="dropdown-content">

    <li> <a  routerLink="/app-landing">English</a></li>
    <li> <a routerLink="/app-landing">Spanish</a></li>
    <li><a routerLink="/app-landing">Chinese</a> </li>


    </div>
    </div>

    </li>

    </ul>

    <div class="navbar-btn">
        <a href="https://appopay.com/appopayadmin/#/system/login" target="_blank"> Customer Login</a>
    </div>
    <div class="navbar-btn">
        <a href="https://appopay.com/merchantadmin/#/system/login" target="_blank">Merchant Login</a>
    </div>
    </div>
    </div>
</nav>
<style>
    .dropdown {
        float: left;
        overflow: hidden;
    }

    .dropdown {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding: 1px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
    }




    .dropdown-content {
        display: none;
        position: fixed;

        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        float: none;
        color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        background-color: #0899e7;
    }

    .dropdown-content a:hover {
        background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
</style>


<!-- Start Contact Area -->
<section class="contact-area pt-100 pb-70" style="margin-top: 50px;">
    <div class="container">
        <div class="col-lg-12 col-md-12 section-title">
            <h2> Drop us a message for more information</h2>
            <div class="bar"></div>

        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <ul>



                       <li>
                            <div class="icon">
                                <i class="fa fa-map-marker"></i>
                            </div>
                            <span>Address</span>

                            HEADQUARTERS:
                            Calle Punta Darién, Punta Pacifica, P.H. Torre de las Américas,
                            Suite 2905, Panamá City, Panamá
                        </li>

                        <li>
                            <div class="icon">
                                <i class="fa fa-envelope"></i>
                            </div>
                            <span> Email</span>
                            <a href="#">support@appopay.com</a>

                        </li>

                        <li>
                            <div class="icon">
                                <i class="fa fa-phone"></i>
                            </div>
                            <span> Phone</span>
                            <a href="#"> +507 216 8085 </a>

                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form  >
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">

                                    <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder=" Name" ngModel>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder=" Email" ngModel >
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder=" Phone" ngModel>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder=" Subject" ngModel>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6" required data-error="Write your message" placeholder=" Your Message" ngModel></textarea>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button  type="submit" class="btn btn-primary">Submit</button>
                                <div id="msgSubmit" class="h3 text-center hidden"></div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>


</section>

<app-footer></app-footer>
